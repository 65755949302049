
          @import "@/assets/css/_variables.scss";
        

@import "~@/assets/font/archivo/archivo.scss";
@import "~@/assets/font/maven-pro/maven-pro.scss";
@import "~@/assets/font/montserrat/montserrat.scss";
@import "~@/assets/font/poppins/poppins.scss";
@import "~@/assets/font/roboto/roboto.scss";
@import "~@/assets/font/roboto-slab/roboto-slab.scss";

.social-media-story {
  &__desc {
    color: $shade-900;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__look-fields {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    & > div {
      width: 100%;
    }
  }

  &__preview-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $shade-900;
    margin-bottom: 4px;
  }

  &__container {
    border: 1px solid $shade-300;
    border-radius: 7px;
    box-shadow: 0 1px 1px rgba(115, 134, 169, 0.06);
    padding: 20px;
    margin-bottom: 16px;

    &__img {
      width: 207px;
      display: flex;
      justify-content: center;
      position: relative;
      margin: 0 auto;
      overflow: hidden;

      &__cover {
        background: linear-gradient(180deg, rgba(0, 13, 39, 0.4) 0%, rgba(0, 13, 39, 0.1) 100%);
        height: 100%;
        height: -moz-available;
        height: -webkit-fill-available;
        height: stretch;
        position: absolute;
        width: 207px;
      }

      img {
        width: 207px;
        border-radius: 4px;
      }

      &__data {
        padding: 8px 13px;
        border-radius: 4px;
        position: absolute;
        margin-top: 7%;
        height: 100%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        color: $white;

        &__title {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
        }

        &__description {
          font-weight: 500;
          text-align: center;
          font-size: 8px;
          line-height: 12px;
          margin-bottom: 6px;
          position: relative;
          z-index: 1;
        }

        &__watermark {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px 3px;
          gap: 3px;
          background: rgba(255, 255, 255, 0.8);
          border-radius: 2px;
          position: absolute;
          z-index: 0;
          right: 18px;
          bottom: 53px;

          span {
            font-size: 5px;
            color: #00143C;
            line-height: 11px;
          }

          img {
            width: 20px;
          }
        }

        &__date-item {
          font-size: 8px;
          font-weight: 700;
          line-height: 12px;
          text-align: center;
          margin-top: 30px;
          position: relative;
          z-index: 1;
        }

        &__date {
          font-weight: 500;
          text-align: center;
          font-size: 8px;
          line-height: 12px;
          position: relative;
          z-index: 1;
        }

        &__slots {
          margin-bottom: 4px;
          position: relative;
          z-index: 1;

          &__item {
            font-size: 8px;
            font-weight: 700;
            line-height: 12px;
            text-align: center;
          }
        }
      }
    }
  }

  &__download {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;

    .adm-button {
      margin-top: 24px
    }

    .adm-form-item {
      width: 100%;
      max-width: 384px;
      margin-bottom: 0;
    }

    &__copy {
      cursor: pointer;
    }
  }
}
