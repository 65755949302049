
          @import "@/assets/css/_variables.scss";
        

.adm-editor {
  .quill-html-editor {
    textarea {
      border-top: none;
      border-radius: 0 0 7px 7px;
    }
  }

  .quill-editor {
    .ql-html:hover:before {
      color: #06c;
    }

    .ql-tooltip {
      z-index: 10;
      white-space: normal;
      a.ql-action:after {
        margin-left: 8px !important;
      }
    }

    .ql-html:before {
      content: "</>";
      display: inline-block;
      line-height: 22px;
      border: 1px solid transparent;
      color: #444444;
      font-size: 14px;
      font-weight: 500;
    }

    button.ql-html {
      height: 26px;
      padding: 0;

      &--active:before {
        color: #06c;
      }
    }

    .ql-toolbar {
      border-color: $shade-300;
      border-radius: 7px 7px 0 0;
    }

    .ql-container {
      border-color: $shade-300;
      border-radius: 0 0 7px 7px;
      min-height: inherit;

      .ql-editor {
        height: 100%;
      }
    }
  }
}
