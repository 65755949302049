
          @import "@/assets/css/_variables.scss";
        

.extras-details {
  display: flex;
  align-items: center;
  max-width: 100%;

  &:not(:first-child) {
    margin-top: 4px;
    padding-top: 4px;
    border-top: 1px dashed $shade-300;
  }

  svg {
    width: 20px;
    flex: 0 0 auto;

    path {
      fill: $shade-900;
    }
  }

  span {
    font-size: 12px;
    line-height: 18px;
  }

  &__duration {
    white-space: nowrap;
  }

  &__name {
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
