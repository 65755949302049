
          @import "@/assets/css/_variables.scss";
        

#appointment {
  .appointment-form {
    @include media-breakpoint-down($bp-small-max) {
      padding: 1rem;
      display: block;
      margin: 0 -1rem;
    }

    .left-side {
      @include media-breakpoint-down($bp-small-max) {
        width: 100%;
        padding-right: 0;
        border: none;
      }
    }

    .right-side {
      border-left: 1px solid $shade-300;
      width: 40%;
      padding-left: 1.5rem;
      margin-left: 1.5rem;
      position: relative;
      display: flex;
      flex-direction: column;

      @include phone-down {
        padding: 0;
        width: 100%;
        margin-left: 0;
        border: 0;
      }
    }
  }

  .send-notification-to-customer {
    margin-top: 24px;
  }
}
