
          @import "@/assets/css/_variables.scss";
        

.rate-trafft-dialog {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__description {
      color: $shade-800;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
    }

    &__rate-options {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;

      &__card {
        border-radius: 4px;
        background: $shade-150;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid transparent;
        cursor: pointer;

        @include tablet-up {
          &__external-link {
            visibility: hidden;
          }
        }

        &__option {
          display: flex;
          align-items: center;
          gap: 16px;

          img {
            width: 28px;
            height: 16px;
          }

          &__name {
            color: $shade-900;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
          }
        }

        &:hover {
          border: 2px solid var(--primary-600);
          background: var(--visualpicker-linear, linear-gradient(180deg, var(--primary-400) 0%, var(--primary-200) 100%));
          text-decoration: none;

          span {
            color: var(--primary-900) !important;
          }

          @include tablet-up {
            div {
              visibility: visible !important;
            }
          }
        }
      }
    }
  }
}
