
          @import "@/assets/css/_variables.scss";
        

.appointment-extra-card {
  border: 1px solid $shade-300;
  border-radius: 7px;

  &__header {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin: 8px 8px 0;
    padding-bottom: 8px;
    border-bottom: 1px solid $shade-300;
  }

  &__body {
    padding: 8px;
    display: flex;
    gap: 24px;

    &__block {
      max-width: 100px;

      &__label {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 4px;
      }

      &__content {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
