
          @import "@/assets/css/_variables.scss";
        

// Dashboard
.adm-dashboard {
  display: flex;
  position: relative;
  margin-left: 72px;
  top: var(--alert-height);

  @include tablet-down {
    margin-left: 0;
  }

  &__app-banner {
    @include phone-down {
      display: none;
    }
  }

  &__bc-promotion-banner {
    margin-top: calc(60px + var(--alert-height));

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__promo-2024-banner {
    margin-top: 60px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  // Container
  &__container {
    background-color: $shade-200;
    min-height: calc(100vh - var(--alert-height));
    width: 100%;

    // Main Wrapper
    &__wrapper {
      width: 100%;
      margin: 60px auto 0;
      padding: 1.5rem 2.5rem 2.5rem;
      background-size: 100% auto;
      position: relative;

      &__promo-2024-banner-wrapper {
        margin: 0 auto 0;
      }

      &__bc-promo-banner-visible {
        margin: 175px auto 0;
      }

      // TODO: Remove when all page-content blocks will be replaced with a component
      // Page Content
      .page-content {
        padding: 1.5rem 1.75rem;
        background: $adm-white;
        border-radius: 5px;
        box-shadow: $box-shadow-page-content;
        overflow-x: hidden;
        min-height: 600px;

        @include tablet-down {
          padding: 1rem;
          margin: 0 -16px;
        }

        .actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &.services-list {
          overflow: unset;
        }
      }

      // With Pagination
      .pagination-bottom {
        position: relative;
        padding-bottom: 61px;
      }

      // With Sidebar
      .content-with-sidebar {
        height: 100%;
      }

      // With Dummy Alert
      .is-dummy-alert-visible {
        padding: 1rem 1.75rem;
      }

      @include tablet-down {
        padding: 1.25rem 1rem;
        margin: 56px auto 66px;
      }
    }
  }



  // Main Navigation Bar Expanded
  &.is-main-navigation-bar-expanded {
    margin-left: 84px;

    @include tablet-down {
      margin-left: 0;
    }
  }
}
.bb-feedback-button {
  z-index: 2;

  @include tablet-down {
    display: none!important;
  }
}
