
          @import "@/assets/css/_variables.scss";
        

.share-datetime-section {

  &__add-date {
    margin-top: 4px;
  }
}
