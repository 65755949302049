
          @import "@/assets/css/_variables.scss";
        

// Dropdown Menu
.adm-dropdown-menu {
  box-shadow: $box-shadow-dropdown;
  border-radius: 4px;
  border: 1px solid $shade-200;
  background: $adm-white;
  padding: 5px 7px;
  min-width: 120px;

  // Margin From Triggering Element
  &[x-placement^=bottom] {
    margin-top: 0.25rem;
  }
}
