
          @import "@/assets/css/_variables.scss";
        

// Badge
.adm-badge {
  justify-content: center;
  border: none;
  font-weight: 600;
  height: 1rem;
  min-width: 1rem;
}
