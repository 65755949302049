
          @import "@/assets/css/_variables.scss";
        

// Dropdown Item
.adm-dropdown-item {
  padding: 0;
  margin: 0;
  overflow: hidden;
  border-radius: 5px;
  cursor: default;

  // Hover & Focus
  &:hover:not(.is-disabled), &:focus {
    background-color: $adm-white;
    color: initial;
  }

  // Divided
  &.is-divided {
    padding-top: 0.25rem;
    margin-top: 0.25rem;
    border-top: 1px solid $shade-300;
    border-radius: 0;

    &.is-divided-dashed {
      border-top: 1px dashed $shade-300;
    }

    // Hover
    &:hover {
      background-color: $adm-white;
      color: initial;
    }

    // Before
    &:before {
      content: none;
    }
  }

  // Wrapper
  &__wrapper {
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 14px;
    padding: 3px 7px 3px 7px;
    line-height: 24px;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $shade-900;

    // Hover
    &:hover:not(.is-disabled) {
      background: $shade-200;
    }

    // Hover Not Active
    &:hover:not(.is-active):not(:active) {
      color: $shade-900;
    }

    // Active
    &:active:not(.is-disabled) {
      background: $shade-250;
    }

    // Disabled
    &.is-disabled {
      cursor: default;
      color: $shade-600;
      pointer-events: none;
    }

    // Red
    &.is-red {
      color: $red-900;

      // States & Icon
      &:hover, &:focus, &:active, i:before {
        color: $red-900;
      }
    }

    // Blue
    &.is-blue {
      color: var(--primary-1000);

      // States
      &:hover, &:focus, &:active {
        color: var(--primary-1000);
      }

      // Icon
      i:before {
        color: var(--primary-900)
      }
    }

    // Active
    &.is-active {
      color: var(--primary-900);
      background: var(--primary-300);

      // Active Icon
      &.is-with-active-icon {
        margin-right: 8px;

        // After
        &:after {
          margin-left: auto;
          display: flex;
          justify-content: center;
          width: 24px;
          font-family: 'icomoon', serif;
          content: "\e961";
        }
      }
    }

    // With Icon
    &.is-with-icon {
      padding: 3px 11px 3px 3px;
    }
  }

  // Icon
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 24px;
    width: 24px;
    height: 24px;
    margin-right: 0.25rem;

    // Icon
    i {
      margin-right: 0;
      font-size: 18px;
      display: inline-block;
    }

    &.is-disabled {
      i {
        // Before
        &:before {
          color: $shade-600;
        }
      }
    }
  }

  // Label
  &__label {
    display: inline-flex;
    align-items: center;
    line-height: 24px;
  }
}
