
          @import "@/assets/css/_variables.scss";
        

// Pagination
.adm-pagination {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid $shade-300;
  justify-content: center;

  @include phone-up {
    justify-content: space-between;
  }

  // Page
  &__page {
    display: none;

    @include phone-up {
      font-size: 13px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: $shade-600;
      font-weight: normal;
    }
  }

  // Element Pagination
  .el-pagination {
    padding: 0;

    // Previous & Next Buttons
    .btn-prev, .btn-next {
      min-width: unset;
      padding: 0;
      border-radius: 4px;

      // Icon
      i {
        display: flex;
        align-items: center;
        height: 28px;
        width: 28px;
        justify-content: center;

        // Before
        &:before {
          font-weight: normal;
          color: $shade-700;
          font-size: 24px;
          font-family: 'icomoon', serif;
        }
      }

      // Not Disabled
      &:not(:disabled) {

        // Hover
        &:hover {
          background-color: $shade-200;
        }

        // Active
        &:active {
          background-color: var(--primary-300);

          // Icon Before
          i:before {
            color: var(--primary-900);;
          }
        }
      }
    }

    // Previous Button
    .btn-prev i:before {
      content: "\e965";
    }

    // Next Button
    .btn-next i:before {
      content: "\e902";
    }

    // Button
    button {
      height: 28px;
    }

    // Pager
    .el-pager {

      // Button
      li {
        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;
        font-weight: 500;
        color: $shade-700;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: unset;
        height: 28px;
        width: 28px;

        // Hover
        &:hover {
          background-color: $shade-200;
        }

        // Active
        &:active {
          background-color: var(--primary-300);
          color: var(--primary-900);
        }
      }

      // Active
      li.active {
        background: var(--primary-300);
        color: var(--primary-900);
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
