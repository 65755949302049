
          @import "@/assets/css/_variables.scss";
        

.checkbox-dropdown {
  max-width: 100%;

  &__search {
    font-size: 14px;
    margin-bottom: 0.5rem;

    input {
      font-weight: 500;
      line-height: 24px;
    }
  }

  .el-button {
    &:hover, &:focus {
      color: unset;
      border-color: #C0C4CC;
      background-color: $adm-white;
    }
  }
}

.checkbox-dropdown-menu {
  &.el-dropdown-menu {
    padding: 6px 8px;
    max-width: 90%;
  }
}

.checkbox-dropdown-menu__items {
  max-height: 172px;
  overflow: auto;

  @include phone-up {
    width: 270px;
  }

  &--no-search {
    width: 100%;
  }
}
