
          @import "@/assets/css/_variables.scss";
        

.dashboard-alerts {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  right: 0;

  &__promo-offer-mobile {
    @media screen and (min-width: 1025px) {
      display: none;
    }
  }

  .adm-button--resend-disabled {
    min-width: 110px;
  }
}
