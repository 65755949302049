@font-face {
  font-family: 'icomoon';
  src: url('~@/assets/font/icons/icomoon.eot?flnlzg');
  src: url('~@/assets/font/icons/icomoon.eot?flnlzg#iefix') format('embedded-opentype'),
  url('~@/assets/font/icons/icomoon.ttf?flnlzg') format('truetype'),
  url('~@/assets/font/icons/icomoon.woff?flnlzg') format('woff'),
  url('~@/assets/font/icons/icomoon.svg?flnlzg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="tz-"], [class*=" tz-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tz-commission:before {
  content: "\e997";
  color: #00143c;
}
.tz-revert:before {
  content: "\e996";
  color: #00143c;
}
.tz-bform:before {
  content: "\e995";
  color: #00143c;
}
.tz-heart:before {
  content: "\e994";
  color: #00143c;
}
.tz-refund:before {
  content: "\e992";
  color: #00143c;
}
.tz-label:before {
  content: "\e993";
  color: #00143c;
}
.tz-sort:before {
  content: "\e991";
  color: #00143c;
}
.tz-share:before {
  content: "\e98e";
  color: #00143c;
}
.tz-unlock:before {
  content: "\e990";
  color: #00143c;
}
.tz-videotutorial:before {
  content: "\e98f";
  color: #00143c;
}
.tz-bot:before {
  content: "\e98d";
}
.tz-cloud-upload-outline:before {
  content: "\e98c";
  color: #00143c;
}
.tz-promotions:before {
  content: "\e989";
  color: #00143c;
}
.tz-star-outlined:before {
  content: "\e98a";
  color: #00143c;
}
.tz-vacation:before {
  content: "\e98b";
  color: #00143c;
}
.tz-approved:before {
  content: "\e984";
}
.tz-no-show:before {
  content: "\e985";
}
.tz-cancelled:before {
  content: "\e986";
}
.tz-rejected:before {
  content: "\e987";
}
.tz-pending-status:before {
  content: "\e988";
}
.tz-whatsapp:before {
  content: "\e983";
}
.tz-export:before {
  content: "\e982";
  color: #00143c;
}
.tz-repeat:before {
  content: "\e981";
  color: #00143c;
}
.tz-pending:before {
  content: "\e97f";
}
.tz-dashboard:before {
  content: "\e980";
}
.tz-activity:before {
  content: "\e97e";
}
.tz-import:before {
  content: "\e97d";
}
.tz-language:before {
  content: "\e97c";
}
.tz-clearable:before {
  content: "\e97b";
}
.tz-big-arrow-down:before {
  content: "\e975";
}
.tz-big-arrow-left:before {
  content: "\e976";
}
.tz-big-arrow-right:before {
  content: "\e977";
}
.tz-big-arrow-up:before {
  content: "\e978";
}
.tz-graph-arrow-down1:before {
  content: "\e979";
  color: #00143c;
}
.tz-graph-arrow-up1:before {
  content: "\e97a";
  color: #00143c;
}
.tz-sms:before {
  content: "\e973";
}
.tz-sms-outline:before {
  content: "\e974";
}
.tz-graph-arrow-down:before {
  content: "\e971";
}
.tz-graph-arrow-up:before {
  content: "\e972";
}
.tz-category:before {
  content: "\e970";
}
.tz-collapse:before {
  content: "\e96e";
}
.tz-expand:before {
  content: "\e96f";
}
.tz-radio-icon:before {
  content: "\e966";
}
.tz-attachment:before {
  content: "\e967";
}
.tz-checkbox-icon:before {
  content: "\e968";
}
.tz-checkbox-icon-off:before {
  content: "\e969";
}
.tz-selected-box:before {
  content: "\e96a";
}
.tz-text-area-icon:before {
  content: "\e96b";
}
.tz-text-content:before {
  content: "\e96c";
}
.tz-text-icon:before {
  content: "\e96d";
}
.tz-arrow-left:before {
  content: "\e965";
}
.tz-deposit:before {
  content: "\e963";
}
.tz-percent:before {
  content: "\e964";
}
.tz-paper:before {
  content: "\e962";
}
.tz-check:before {
  content: "\e961";
}
.tz-plane:before {
  content: "\e95f";
}
.tz-email-sent:before {
  content: "\e960";
}
.tz-clear:before {
  content: "\e95e";
}
.tz-arrow-circle-up:before {
  content: "\e95d";
}
.tz-trophy:before {
  content: "\e94e";
}
.tz-arrow-circle-down:before {
  content: "\e94f";
}
.tz-arrow-circle-left:before {
  content: "\e958";
}
.tz-arrow-circle-right:before {
  content: "\e959";
}
.tz-bell:before {
  content: "\e95a";
}
.tz-message-square:before {
  content: "\e95b";
}
.tz-question-mark-circle-outline:before {
  content: "\e95c";
}
.tz-arrow-circle-top:before {
  content: "\e900";
}
.tz-arrow-down:before {
  content: "\e901";
}
.tz-arrow-right:before {
  content: "\e902";
}
.tz-arrow-up:before {
  content: "\e903";
}
.tz-bill:before {
  content: "\e904";
}
.tz-billing:before {
  content: "\e905";
}
.tz-book-outline:before {
  content: "\e906";
}
.tz-bulb-filled:before {
  content: "\e907";
}
.tz-bulbtip:before {
  content: "\e908";
}
.tz-caret-down:before {
  content: "\e950";
}
.tz-caret-down-small:before {
  content: "\e954";
}
.tz-caret-left:before {
  content: "\e951";
}
.tz-caret-left-small:before {
  content: "\e955";
}
.tz-caret-right:before {
  content: "\e952";
}
.tz-caret-right-small:before {
  content: "\e956";
}
.tz-caret-up:before {
  content: "\e953";
}
.tz-caret-up-small:before {
  content: "\e957";
}
.tz-cc:before {
  content: "\e909";
}
.tz-check-circle:before {
  content: "\e90a";
}
.tz-check-filled:before {
  content: "\e90b";
}
.tz-check-square:before {
  content: "\e90c";
}
.tz-circle:before {
  content: "\e90d";
}
.tz-circle-plus:before {
  content: "\e90e";
}
.tz-clock:before {
  content: "\e90f";
}
.tz-close:before {
  content: "\e910";
}
.tz-close-filled:before {
  content: "\e911";
}
.tz-code:before {
  content: "\e912";
}
.tz-cog:before {
  content: "\e913";
}
.tz-coupon:before {
  content: "\e914";
}
.tz-customers:before {
  content: "\e915";
}
.tz-customize:before {
  content: "\e916";
}
.tz-danger-filled:before {
  content: "\e917";
}
.tz-date:before {
  content: "\e918";
}
.tz-date-and-time:before {
  content: "\e919";
}
.tz-double-arrow-right:before {
  content: "\e941";
}
.tz-download:before {
  content: "\e91a";
}
.tz-drag:before {
  content: "\e91b";
}
.tz-edit:before {
  content: "\e91c";
}
.tz-email:before {
  content: "\e91d";
}
.tz-employee:before {
  content: "\e91e";
}
.tz-external-link-outline:before {
  content: "\e91f";
}
.tz-copy:before {
  content: "\e920";
}
.tz-extras:before {
  content: "\e921";
}
.tz-eye:before {
  content: "\e922";
}
.tz-eye-crossed:before {
  content: "\e923";
}
.tz-filters:before {
  content: "\e924";
}
.tz-gallery:before {
  content: "\e925";
}
.tz-globe-outline:before {
  content: "\e926";
}
.tz-home:before {
  content: "\e927";
}
.tz-hours:before {
  content: "\e928";
}
.tz-info:before {
  content: "\e929";
}
.tz-info-filled:before {
  content: "\e92a";
}
.tz-jobs:before {
  content: "\e92b";
}
.tz-link:before {
  content: "\e92c";
}
.tz-list:before {
  content: "\e92d";
}
.tz-loading:before {
  content: "\e94d";
}
.tz-locations:before {
  content: "\e92e";
}
.tz-lock:before {
  content: "\e92f";
}
.tz-login:before {
  content: "\e930";
}
.tz-logout:before {
  content: "\e931";
}
.tz-menu:before {
  content: "\e932";
}
.tz-minus:before {
  content: "\e933";
}
.tz-more-options:before {
  content: "\e934";
}
.tz-notes:before {
  content: "\e935";
}
.tz-notification:before {
  content: "\e936";
}
.tz-on-site:before {
  content: "\e937";
}
.tz-password:before {
  content: "\e938";
}
.tz-payments:before {
  content: "\e939";
}
.tz-phone:before {
  content: "\e93a";
}
.tz-plan:before {
  content: "\e93b";
}
.tz-plus:before {
  content: "\e93c";
}
.tz-roles:before {
  content: "\e93d";
}
.tz-save:before {
  content: "\e93e";
}
.tz-search:before {
  content: "\e93f";
}
.tz-services:before {
  content: "\e940";
}
.tz-slash:before {
  content: "\e942";
}
.tz-star:before {
  content: "\e94c";
}
.tz-stopwatch:before {
  content: "\e943";
}
.tz-time-back:before {
  content: "\e944";
}
.tz-timezy:before {
  content: "\e945";
}
.tz-total:before {
  content: "\e946";
}
.tz-trash:before {
  content: "\e947";
}
.tz-user:before {
  content: "\e948";
}
.tz-view:before {
  content: "\e949";
}
.tz-warning-filled:before {
  content: "\e94a";
}
.tz-weekly-stats:before {
  content: "\e94b";
}
