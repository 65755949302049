
          @import "@/assets/css/_variables.scss";
        

.share-appointment {
  &__iframe {
    margin-bottom: 0;
  }

  &__email {
    &__title {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: $shade-900;
      margin-bottom: 8px;
    }

    &__container {
      gap: 8px;
      border: 1px solid $shade-300;
      border-radius: 4px;
      margin-bottom: 16px;
      max-width: 100%;
      overflow-y: auto;
      padding: 6px;

      @include phone-up {
        padding: 24px;
      }
    }
  }

  &__copy-link-button {
    display: flex;
    cursor: pointer;
  }

  &__share-buttons {
    margin-bottom: 0;

    &__title {
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    &__buttons {
      margin: -6px;
      display: flex;

      @include phone-down {
        flex-direction: column;
        align-items: flex-start;
      }

      & > button {
        margin: 6px;
      }
    }
  }

  &__details {
    margin-top: 16px;

    &__label {
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 8px;
    }

    &__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      background: var(--primary-200);
      border: 1px solid var(--primary-400);
      border-radius: 4px;
      padding: 16px 16px 8px;

      &__date {
        color: $shade-900;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }

      &__timeslots {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        margin-bottom: 8px;
      }

      &__extra {
        display: flex;
        align-items: center;

        &__item {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 220px;
          margin-right: 4px;
        }
      }
    }
  }

  // QR code
  &__qr-code {
    &__content {

      &__title {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: $shade-900;
      }

      &__generated-code {
        padding: 24px 0;
        gap: 8px;
        max-width: 600px;
        height: 304px;
        background: var(--primary-400);
        margin-bottom: 12px;
        margin-top: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 256px;
        }
      }

      &__actions {
        display: flex;
        align-items: flex-start;
        column-gap: 24px;
        row-gap: 16px;
        flex-wrap: wrap;
      }
    }
  }
}
