// Colors
$adm-black: #00040D;
$adm-white: #FFFFFF;
$shade-1000: #000D27;
$shade-900: #00143C;
$shade-800: #334363;
$shade-700: #66728A;
$shade-600: #808A9E;
$shade-500: #99A1B1;
$shade-450: #B3B9C5;
$shade-400: #CCD0D8;
$shade-300: #DEE1E6;
$shade-250: #EDEFF3;
$shade-200: #F4F6FA;
$shade-150: #F7F8F9;
$green-1000: #16A862;
$green-900: #18CA75;
$green-800: #52D797;
$green-700: #80E2B3;
$green-600: #AFEDCF;
$green-500: #D1F4E3;
$green-400: #E8FAF2;
$green-300: #F3FCF8;
$red-1000: #CF074E;
$red-900: #E90E5C;
$red-800: #EF4A85;
$red-700: #F487AE;
$red-600: #F7ABC6;
$red-500: #FBCFDE;
$red-400: #FDE7EF;
$red-300: #FEF3F7;
$yellow-1000: #E4A30B;
$yellow-900: #FAB005;
$yellow-800: #FBC444;
$yellow-700: #FCD476;
$yellow-600: #FDE4A8;
$yellow-500: #FEEFCD;
$yellow-400: #FFF8E6;
$yellow-300: #FEFBF2;
$linkedin-blue: #0A66C2;
$linkedin-blue-hover: #3C76CB;
$facebook-blue: #1877F2;
$facebook-blue-hover: #2F84F3;
$divider-color: #DDE3E9;
$blue-300: #F2F6FE;


// Booking Website Colors - @TODO - Remove after migrating Custom Fields Preview to iFrame - DO NOT USE ON ADMIN
$black: #212529;
$white: #FFF;
$cloudy: #F8F9FA;
$metal: #E6EBEF;
$concrete: #CED4DA;
$graphite: #868E96;
$steel-grey: #99A1B1;
$secondary-main: #343A40;
$black-lighter: #3D4246;
$general-red: #F01038;

// Booking Website Elevations - @TODO - Remove after migrating Custom Fields Preview to iFrame - DO NOT USE ON ADMIN
$elevation-small: 0 1px 1px rgba(0, 0, 0, 0.13), 0 2px 4px rgba(0, 0, 0, 0.04);
$elevation-medium: 0 0 1px rgba(0, 0, 0, 0.17), 0 3px 5px rgba(0, 0, 0, 0.06);
$elevation-large: 0 9px 19px rgba(0, 0, 0, 0.07), 0 1px 2px rgba(0, 0, 0, 0.125);

// Box Shadow
$box-shadow-dropdown: 0px 1px 1px rgba($shade-900, 0.24), 0px 2px 6px rgba($shade-900, 0.17);
$box-shadow-page-content: 0px 2px 7px rgba(11, 7, 58, 0.14), 0px 17px 35px rgba(15, 37, 74, 0.1);
$box-shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
$box-shadow-large: 0 3px 12px 0 rgba(0, 0, 0, 0.2);

// Font
//noinspection CssUnknownTarget
@import "@/assets/font/inter/inter";
$font-size: 16px;
$font-family: 'Inter';

// Height
$label-line-height: 1.5rem;

// Breakpoints - TODO - Remove from admin, use Media Mixins
$bp-xsmall-max: 374px;
$bp-small-max: 767px;
$bp-small-min: 768px;
$bp-medium-max: 991px;
$bp-large-max: 1199px;
$bp-large-min: 1200px;
$bp-xlarge-min: 1920px;

// Responsive
$tablet: 1024px;
$phone: 768px;

// Media Mixins
@mixin media-breakpoint-down($breakpoint) {
  @if $breakpoint {
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}

@mixin media-breakpoint-up($breakpoint) {
  @if $breakpoint {
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

@mixin tablet-up {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin tablet-only {
  @media (max-width: $tablet) and (min-width: $phone + 1) {
    @content;
  }
}

@mixin phone-up {
  @media (min-width: $phone + 1) {
    @content;
  }
}

@mixin phone-down {
  @media (max-width: $phone) {
    @content;
  }
}

