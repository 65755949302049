
          @import "@/assets/css/_variables.scss";
        

.adm-confirm {
  &__description {
    white-space: pre-line;
  }

  &__more-options {
    margin-top: 8px;
  }
}
