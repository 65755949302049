
          @import "@/assets/css/_variables.scss";
        

.share-booking-form {
  &--shareOptions {
    .el-dialog {
      max-width: 816px;

      @include phone-up {
        width: 816px;
      }

      &__wrapper {
        height: 100vh;
      }
    }
  }

  &--entities {
    .el-dialog {
      max-width: 484px;
    }
  }

  &--socialMediaImages {
    .el-dialog {
      max-width: 648px;
    }
  }

  &--share {
    .el-dialog {
      max-width: 648px;

      @include phone-up {
        width: 816px;
      }
    }
  }
}
