
          @import "@/assets/css/_variables.scss";
        

// Billing Dialog
.ad-billing-dialog {

  .el-dialog__body {
    padding: 0 16px 24px;
  }

  & > .el-dialog__wrapper > .el-dialog {
    background: $shade-200 url('~@/assets/img/hero.svg') repeat-x top center;
    border-radius: 0;

    @include media-breakpoint-down($bp-large-max) {
      width: inherit;
    }
    @include media-breakpoint-down($bp-small-max) {
      width: inherit;
    }
  }

  // Toolbar
  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;

    &__close {
      cursor: pointer;
      width: 40px;
      height: 40px;
      order: 2;

      path {
        fill: $shade-500;
      }
    }
  }
}
