
          @import "@/assets/css/_variables.scss";
        

.app-announcement-banner {
  margin: 0 auto 24px;
  position: relative;
  height: 212px;
  max-width: 360px;
  background: $white;
  border-radius: 12px;
  border: 1px solid $shade-250;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07), 0px 16px 45px 0px rgba(0, 0, 0, 0.12);

  @include phone-up {
    position: fixed;
    z-index: 999;
    left: 24px;
    bottom: 66px;
    width: 360px;
    max-width: 360px;
  }

  @include tablet-up {
    left: 108px;
    bottom: 0;
  }

  &__close-btn {
    position: absolute;
    right: -14px;
    top: -14px;

    svg {
      width: 28px;
      height: 28px;
      cursor: pointer;
    }

    svg path {
      fill: $shade-800;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;

    &__app {
      position: relative;
      display: flex;
      justify-content: center;
      flex-basis: 50%;
      border-radius: 12px 0 0 12px;
      background: url("~@/assets/img/phone-qr-code.png") no-repeat center;
      background-size: contain;

      @media screen and (max-width: 389px){
        background-size: cover;
      }

      img {
        width: 104px;
        height: 104px;
        margin-top: 48px;

        @media screen and (min-width: 2048px){
          width: 124px;
          height: 124px;
        }
      }
    }

    &__get-app {
      display: flex;
      flex-basis: 50%;
      height: 100%;
      padding: 16px;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;

      &__text {
        display: flex;
        flex-direction: column;

        @include tablet-up {
          gap: 4px;
        }

        span {
          color: $shade-900;
          font-size: 13px;
          font-weight: 600;
          line-height: 16px;

          @include tablet-up {
            font-size: 14px;
          }
        }

        p {
          color: $shade-800;
          font-size: 11px;
          line-height: 14px;

          @include tablet-up {
            font-size: 12px;
          }
        }
      }

      img {
        cursor: pointer;
      }
    }
  }
}
