
          @import "@/assets/css/_variables.scss";
        

.refer-friend-dialog {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    img {
      width: 100%;
    }

    &__desc {
      max-width: 490px;
      margin: 0 auto;
      color: $shade-800;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
    }

    &__section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 12px;
      flex-shrink: 1;
      border-bottom: 1px solid $shade-300;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      .adm-form-item {
        width: 100%;
      }

      .adm-button {
        margin-top: 23px;
      }
    }
  }
}
