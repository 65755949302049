
          @import "@/assets/css/_variables.scss";
        

// Color Picker
.adm-color-picker {

  // Element Picker
  &__element {
    border-radius: 4px;
    height: auto;
    line-height: 0;
    position: absolute;
    top: 8px;
    right: 8px;

    &--border {
      border: 1px solid $shade-300;
      top: 7px;
    }

    // Trigger
    .el-color-picker__trigger {
      border: none;
      height: 1.5rem;
      width: 1.5rem;
      padding: 0;

      .el-color-picker__color {
        border-radius: 4px;
        border: none;
      }

      .el-color-picker__color-inner {
        border-radius: 4px;
      }
    }
  }
}

.el-color-picker__panel {
  .el-color-dropdown__btns .el-color-dropdown__link-btn {
    display: none;
  }
}
