
          @import "@/assets/css/_variables.scss";
        

#email-unsubscribe {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 48px;
  height: 100vh;
  background: #F5F6FA url('~@/assets/img/hero.svg') no-repeat top;
  background-size: 100% auto;

  position: relative;

  .unsubscribe-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 49px;

    img {
      height: 40px;
    }
  }

  .unsubscribe-form-container {
    margin: 0 auto;
    background: $adm-white;
    max-width: 456px;
    padding: 48px;
    @media screen and (max-width: $bp-small-min) {
      padding: 15px;
    }
    box-shadow: 0 22px 36px rgba(0, 20, 60, 0.07), 0 2.75474px 4.50776px rgba(0, 20, 60, 0.035);
    border-radius: 13px;

    .adm-form-item {
      margin-bottom: 16px;
    }

    .el-form-item__label {
      font-size: 15px;
      font-weight: normal;
      text-align: left;
      padding-left: 0;
      padding-bottom: 8px;
    }

    .el-radio {
      margin-bottom: 8px;

      &__label {
        padding-left: 8px;
        font-size: 15px;
        font-weight: normal;
      }
    }
  }

  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -1px;
    margin-bottom: 24px;
  }

}
