
          @import "@/assets/css/_variables.scss";
        

// Date Picker Wrapper
.adm-date-picker-wrapper {
  width: 100%;

  // Date Picker
  .adm-date-picker {
    cursor: pointer;
    width: 100%;

    // Start Icon
    i.el-input__icon {
      display: flex;
      align-items: center;

      // Before
      &:before {
        color: $shade-900;
        font-size: 24px;
        font-family: 'icomoon', serif;
      }
    }

    &.is-disabled {
      i.el-input__icon {
        &:before {
          color: $shade-600;
        }
      }
    }

    // Date Icon
    i.el-icon-date:before {
      content: "\e918";
    }

    // Suffix
    .el-input__suffix {
      right: 8px;

      // Icon
      i {
        display: flex;

        // Before
        &:before {
          color: $shade-900;
          font-size: 24px;
          font-family: 'icomoon', serif;
        }
      }

      // Hides validation icon
      .el-input__validateIcon {
        display: none;
      }

      // Clear Icon
      i.el-icon-circle-close {
        display: flex;
        justify-content: center;

        // Before
        &:before {
          color: $shade-700;
          font-size: 20px;
          content: "\e95e";
        }
      }
    }

    // Hover
    &:hover:not(:focus) .el-input__inner:not(.is-focus):not(:focus) {
      border: 1px solid $shade-300;
      box-shadow: inset 0 1px 2px rgba(20, 35, 61, 0.11);
    }

    // Native Input
    .el-input__inner {
      cursor: pointer;
      border: 1px solid $shade-300;
      border-radius: 7px;
      font-size: 15px;
      -webkit-transition: box-shadow 0.15s;
      transition: box-shadow 0.15s;

      // Hover
      &:hover:not(:focus):not(:disabled) {
        box-shadow: inset 0 1px 2px rgba(20, 35, 61, 0.11);
      }

      // Active & Focus
      &:active, &:focus {
        border: 1px solid var(--primary-900);
        box-shadow: 0 1px 1px rgba(115, 134, 169, 0.06);
      }

      // Placeholder
      &::-webkit-input-placeholder, &:-ms-input-placeholder, &::placeholder {
        color: $shade-600;
      }

      // Disabled
      &:disabled {

        // Placeholder
        &::-webkit-input-placeholder, &:-ms-input-placeholder, &::placeholder {
          color: $shade-450;
        }
      }
    }

    // Disabled
    &.is-disabled {

      // Suffix
      .el-input__suffix i:before {
        color: $shade-600;
      }

      // Input
      input {
        background: $shade-150;
        border-color: $shade-300;
        box-shadow: none;
        cursor: not-allowed;
        color: $shade-600;

        // Hover
        &:hover {
          box-shadow: unset;
        }

        // Active & Focus
        &:active, &:focus {
          border: 1px solid $shade-300;
          box-shadow: unset;
        }
      }
    }

    // Size
    &__size {

      // Default
      &__default {

        // Prefix
        .el-input__prefix {
          display: flex;
          align-items: center;
          left: 0.5rem;

          // Icon
          i {
            color: $shade-900;
            font-size: 24px;
            font-family: 'icomoon', serif;

            // Before
            &:before {
              line-height: 40px;
            }
          }
        }

        // Native Input
        .el-input__inner {
          padding: 0 40px;
          height: 40px;
        }
      }

      // Medium
      &__medium {

        // Prefix
        .el-input__prefix {
          display: flex;
          align-items: center;
          left: 6px;

          // Icon
          i {
            color: $shade-900;
            font-size: 24px;
            font-family: 'icomoon', serif;

            // Before
            &:before {
              line-height: 36px;
            }
          }
        }

        // Suffix
        .el-input__suffix {
          right: 6px;

          // Icon Before
          i:before {
            line-height: 36px;
          }
        }

        // Native Input
        .el-input__inner {
          padding: 0 36px;
          height: 36px;
        }

        // Hides validation icon
        .el-input__validateIcon {
          display: none;
        }
      }

      // Small
      &__small {

        // Prefix
        .el-input__prefix {
          display: flex;
          align-items: center;
          left: 6px;

          // Icon
          i {
            color: $shade-900;
            font-size: 20px;
            font-family: 'icomoon', serif;

            // Before
            &:before {
              line-height: 32px;
            }
          }
        }

        // Suffix
        .el-input__suffix {
          right: 6px;

          // Icon
          i:before {
            line-height: 32px;
            font-size: 20px;
          }

          // Hides validation icon
          .el-input__validateIcon {
            display: none;
          }
        }

        // Native Input
        .el-input__inner {
          font-size: 14px;
          padding: 0 32px;
          height: 32px;
        }
      }
    }

    // Range
    &.el-range-editor {
      cursor: pointer;
      border: 1px solid $shade-300;
      border-radius: 7px;
      font-size: 15px;
      -webkit-transition: box-shadow 0.15s;
      transition: box-shadow 0.15s;

      // Range Separator
      .el-range-separator {
        display: flex;
        align-items: center;
      }

      // Hover
      &:hover:not(.is-active):not(.is-disabled) {
        border: 1px solid $shade-300;
        box-shadow: inset 0 1px 2px rgba(20, 35, 61, 0.11);
      }

      // Active & Focus
      &.is-active:not(.is-disabled) {
        border: 1px solid var(--primary-900);
        box-shadow: 0 1px 1px rgba(115, 134, 169, 0.06);
      }

      // Input
      input {
        cursor: pointer;

        // Placeholder
        &::-webkit-input-placeholder, &:-ms-input-placeholder, &::placeholder {
          color: $shade-600;
        }

        // Disabled
        &:disabled {
          border: none;

          // Placeholder
          &::-webkit-input-placeholder, &:-ms-input-placeholder, &::placeholder {
            color: $shade-450;
          }
        }
      }

      // Date Icon
      i.el-icon-date {
        margin-left: 0;
      }

      // Clear Icon
      i.el-icon-circle-close {
        display: flex;
        justify-content: center;

        // Before
        &:before {
          color: $shade-700;
          font-size: 20px;
          content: "\e95e";
        }
      }

      // Size
      &.adm-date-picker__size {

        // Default
        &__default {
          padding: 0 12px 0 8px;

          // Date Icon
          i.el-icon-date {
            width: 24px;
            margin-right: 8px;

            // Before
            &:before {
              width: 24px;
              font-size: 24px;
            }
          }
        }

        // Medium
        &__medium {
          padding: 0 10px 0 6px;

          // Date Icon
          i.el-icon-date {
            width: 24px;
            margin-right: 6px;

            // Before
            &:before {
              width: 24px;
              font-size: 24px;
            }
          }
        }

        // Small
        &__small {
          padding: 0 10px 0 6px;

          // Date Icon
          i.el-icon-date {
            width: 20px;
            margin-right: 6px;

            // Before
            &:before {
              width: 20px;
              font-size: 20px;
            }
          }
        }
      }
    }

    &__clearable-hidden {
      .el-input__inner {
        padding-right: 4px;
      }
    }
  }

  // Close Button For Responsive
  &__close-button__wrapper {
    display: none;

    @include phone-down {
      &.visible {
        position: fixed;
        top: 56px;
        left: 0;
        right: 0;
        height: 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 6px;
        z-index: 3000;
        background-color: $adm-white;
      }
    }
  }
}

// Popper
.adm-date-picker-popper {

  // Picker Panel
  &.el-picker-panel {

    .el-date-table td.end-date span,
    .el-date-table td.start-date span,
    .el-date-table td.current:not(.disabled):not(.skeleton-element-datepicker) span {
      background-color: var(--primary-900);
    }

    .el-date-table td.current.skeleton-element-datepicker span {
      background-color: $shade-200;
    }

    .el-date-picker__header-label.active,
    .el-date-picker__header-label:hover,
    .el-month-table td.today .cell,
    .el-month-table td .cell:hover,
    .el-month-table td.current:not(.disabled) .cell,
    .el-date-table td.today span,
    .el-date-table td.available:hover,
    .el-year-table td.today .cell,
    .el-year-table td .cell:hover,
    .el-year-table td.current:not(.disabled) .cell,
    .el-picker-panel__icon-btn:hover,
    .el-picker-panel__shortcut:hover {
      color: var(--primary-900);
    }

    .el-date-table td.today.end-date span,
    .el-date-table td.today.start-date span,
    .el-date-table td.available.today.current span {
      color: $adm-white;
    }

    .el-date-table.is-week-mode .el-date-table__row.current div,
    .el-date-table.is-week-mode .el-date-table__row:hover div,
    .el-date-table td.in-range div,
    .el-date-table td.in-range div:hover {
      background-color: var(--primary-300);
    }
  }
}

// Mobile
@include phone-down {
  // Popper
  .adm-date-picker-popper {
    position: fixed !important;
    height: calc(100vh - 122px) !important;
    top: 56px !important;
    bottom: 66px !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 !important;
    width: 100%;
    overflow-y: auto;
    padding-top: 40px;
    border: 0 !important;
    border-radius: 0;

    // Panel Body
    .el-picker-panel__body {
      display: flex;
      flex-direction: column;
      min-width: 100%;

      // Picker Content
      .el-date-range-picker__content, .el-picker-panel__content {
        width: 100%;
        margin: 0;

        // Left
        &.is-left {
          border-right: 0;
        }
      }
    }

    // Popper Arrow
    .popper__arrow {
      display: none !important;
    }
  }
}

