
          @import "@/assets/css/_variables.scss";
        

// Alert
.adm-alert {
  border-radius: 8px;
  padding: 0.5rem 1.25rem 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 10px;

  @include phone-down {
    flex-direction: column;
    padding: 0.5rem
  }

  // Default
  &__default {
    background-color: $shade-200;
  }

  // Positive
  &__positive {
    background-color: var(--primary-400);

    i:before {
      color: var(--primary-900);
    }
  }

  // Warning
  &__warning {
    background-color: $yellow-800;
  }

  // Danger
  &__danger {
    background-color: $red-900;
    color: $adm-white;

    a {
      color: $adm-white;
    }

    i:before {
      color: $adm-white;
    }
  }

  // Content
  &__content {
    display: flex;
    align-items: flex-start;
    margin-right: auto;
  }

  // Icon Wrapper
  &__icon-wrapper {
    margin-right: 0.25rem;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 20px;
      display: block;
    }
  }

  // Text Wrapper
  &__text-wrapper {
    display: flex;
    flex-direction: column;
    align-self: center;

    // Title
    &__title {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
    }

    // Description
    &__description {
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      word-break: break-word;

      // All Child
      > * {
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
      }

      // Anchor
      a {
        text-decoration: underline;
        font-size: 13px;
        line-height: 24px;
      }
    }

    // Below Slot
    &__below {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  // Right Slot
  &__right {
    display: flex;
    align-items: center;

    @include phone-down {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

  // Full Width
  &.is-full-width {
    display: flex;
  }

  // Without Icon
  &.is-without-icon {
    padding: 0.5rem;
  }

  // Without Description
  &.is-without-description {
    .adm-alert__text-wrapper__title {
      font-weight: 500;
    }
  }
}
