// Popper
.el-popover {
  &.dark {
    border-color: transparent;
    background-color: $shade-900;
    color: $adm-white;
    font-size: 13px;
    line-height: 1.5;

    &.tooltip-replace {
      padding: 10px;
      line-height: 1.2;
      border: 0;
      min-width: unset;
    }

    * {
      color: $adm-white;
    }

    &[x-placement^="bottom"] .popper__arrow::after,
    &[x-placement^="bottom"] .popper__arrow {
      border-bottom-color: $shade-900;
    }

    &[x-placement^="top"] .popper__arrow::after,
    &[x-placement^="top"] .popper__arrow {
      border-top-color: $shade-900;
    }

    &.autocomplete {
      label {
        display: block;
        font-size: 13px;
        font-weight: 600;
        line-height: 1.5;
        color: $shade-500;
        margin-bottom: 0.25rem;
      }

      .el-input {
        .el-input__inner {
          background-color: rgba(255, 255, 255, 0.1);
          color: $adm-white;
          border-color: transparent;
        }

        svg {
          path {
            fill: $shade-500;
          }
        }
      }

      .list {
        max-height: 170px;
        overflow: auto;

        &::-webkit-scrollbar {
          position: absolute;
          width: 5px;
          right: 1px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: $shade-400;
          border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: $shade-500;
        }

        .list-row {
          display: flex;
          align-items: center;
          font-weight: 500;
          cursor: pointer;
          border-radius: 4px;
          padding: 5px 4px;

          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }

          &__label {
            display: inherit;
            align-items: inherit;
          }

          i {
            margin-left: auto;
            order: 2;
          }
        }
      }
    }
  }
}

.el-popover__reference-wrapper {
  .el-popover__reference {
    outline: none;
  }
}

// Icons
.el-dialog__headerbtn .el-dialog__close {
  color: $shade-900;
  font-weight: 600;
  letter-spacing: -1px;
}

.el-icon-loading {
  font-size: 14px;
  vertical-align: bottom;
}

// Image Viewer
.el-image-viewer__mask {
  opacity: 0.8;
}

.el-message-box__status + .el-message-box__message {
  padding-left: 44px;
}

// Disable padding when dialog is opened
.el-popup-parent--hidden {
  padding: 0 !important;
}

