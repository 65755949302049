
          @import "@/assets/css/_variables.scss";
        

// Billing Dialog Checkout
.ad-billing-dialog-checkout {
  max-width: 1000px;
  margin: 1.25rem auto 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  background: $adm-white;
  border-radius: 8px;

  // Header
  &__header {
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid $shade-300;

    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

  // Body
  &__body {
    display: flex;
    border-bottom: 1px solid $shade-300;

    @include phone-down {
      flex-direction: column;
    }

    // Checkout
    &__checkout {
      width: 628px;
      padding: 28px 37px;
      border-right: 1px solid $shade-300;

      @include phone-down {
        border: none;
        width: 100%;
        padding: 16px;

      }

      // Back
      &__back {
        justify-content: center;
        display: flex;
      }

      // Checkout Loader
      &__loader {
        padding: 0.75rem;
      }
    }

    // Details
    &__details {
      padding: 28px;
      flex-grow: 1;

      // Details Header
      &__header {
        display: flex;

        // Details Header Title
        &__title {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }

        // Details Header Change
        &__change {
          font-size: 14px;
          line-height: 24px;
          color: var(--primary-900);
          cursor: pointer;
          display: flex;
          margin-left: auto;
          order: 2;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      // Details Plan
      &__plan {
        margin-top: 1rem;
        background: $adm-white;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        // Details Plan Header
        &__header {
          padding: 0.75rem 1rem;
          background: var(--primary-200);
          display: flex;
          align-items: center;

          // Details Plan Header Icon
          &__icon {
            display: flex;
            margin-right: 0.75rem;

            img {
              width: 32px;
              height: 32px;
            }
          }

          // Details Plan Header Name
          &__name {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }

          // Details Plan Header Price
          &__price {
            display: flex;
            margin-left: auto;
            order: 2;
            flex-direction: column;

            // Details Plan Header Price Amount
            &__amount {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              margin-left: auto;
            }

            // Details Plan Header Price Billing Interval
            &__interval {
              font-weight: 500;
              font-size: 13px;
              line-height: 16px;
              color: $shade-500;
            }
          }
        }

        // Details Plan Includes
        &__includes {
          padding: 1rem 1.5rem;

          &__item {
            display: flex;
            align-items: flex-start;
            padding: 0.5rem 0;
            border-bottom: 1px dashed $shade-300;

            &:first-child {
              padding-top: 0;
            }

            &:last-child {
              border-bottom: none;
              padding-bottom: 0;
            }

            span {
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }

      // Details SMS Plan
      &__plan-sms {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
        padding: 12px 16px;
        border-radius: 5px;
        display: flex;
        align-items: center;
      }

      // Details Total
      &__total {
        padding: 1.25rem 8px 4px;

        &__item {
          display: flex;
          padding: 0.75rem 0;
          border-bottom: 1px solid $shade-200;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
        }

        &__type {
          font-size: 14px;
          line-height: 24px;
        }

        &__amount {
          font-size: 14px;
          line-height: 24px;
          margin-left: auto;
        }
      }

      // Details Recurring
      &__recurring {
        display: flex;
        width: max-content;
        margin-left: auto;
        font-weight: 500;
        color: $shade-500;
        padding-top: 4px;
        border-top: 1px dashed $shade-200;
      }
    }
  }

  // Footer
  &__footer {
    padding: 1.125rem 1.5rem;

    // Links
    &__links {
      a {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
      }

      span {
        color: $shade-500;
      }

      &__divider {
        margin: 0 0.75rem;
      }
    }
  }
}
