
          @import "@/assets/css/_variables.scss";
        

// Form Item
.adm-form-item {
  margin-bottom: 1.5rem;

  // Element Form Item
  .el-form-item {
    margin-bottom: 0;
    position: relative;
  }

  // Label
  .el-form-item__label {
    text-align: left;
    display: inline-flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 0.25rem;
    color: $shade-900;
    float: none;
    vertical-align: top;
  }

  // Required
  .is-required:not(.is-no-asterisk) {
    .el-form-item__label {
      &::before {
        content: '';
        margin-right: 0;
        vertical-align: unset;
      }

      &::after {
        content: '*';
        color: $red-900;
        margin-left: 1px;
        vertical-align: top;
      }
    }
  }

  &__translate {
    user-select: none;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    color: var(--primary-900);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
  }

  // With Message
  &.is-with-message {

    // Element Form Item
    .el-form-item {
      margin-bottom: 0.25rem;
    }
  }

  // Description
  &__description {
    color: $shade-700;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    word-break: break-word;

    a {
      font-size: 13px;
    }
  }

  // Error
  &__error {
    color: $red-1000;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    word-break: break-word;
  }

  // Error
  .el-form-item.is-error {

    // Input
    .el-input__inner, .adm-currency-input {
      border: 1px solid $red-900;
    }

    .adm-select-wrapper .adm-select:hover:not(:focus) .el-input:not(.is-disabled) .el-input__inner:not(.is-focus):not(:focus) {
      border: 1px solid $red-900;
    }

    // Message
    .el-form-item__error {
      display: none;
    }

    // Date Picker Clear Icon
    .adm-date-picker-wrapper {
      .el-input__validateIcon {
        display: none;
      }
    }
  }
}
