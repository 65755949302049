#app {
  // Buttons
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    margin-top: 2rem;
    border-top: 1px solid $shade-300;
  }

  // Drag-handle
  .draggable .drag-handle {
    cursor: move;
    margin-right: 0.5rem;

    svg {
      display: block;

      path {
        fill: $shade-500;
      }
    }
  }

  // Loader
  .loader {
    &.filter-loader {
      position: relative;
      box-shadow: none;
      width: 100%;
      height: auto;
      top: 0;
      left: 0;
    }

    position: absolute;
    top: 5rem;
    left: 2.5rem;
    width: calc(100% - 5rem);
    height: calc(100% - 11.5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
    background-color: $adm-white;
    border-radius: 5px;
    box-shadow: $box-shadow-page-content;
    z-index: 100;

    img, svg {
      max-width: 80px;
      display: block;
    }
  }

  .filter-loader {
    position: relative;
  }

  // Row
  .head-row {
    padding: 0 0.5rem;
    border-bottom: 1px solid $shade-300;

    p {
      color: $shade-500;
      margin: 0 0 0.5rem;
      font-weight: 500;
    }
  }

  // Table Row
  .tm-row {
    padding: 0.5rem;
    display: block;
    align-items: center;
    position: relative;
    border-bottom: 1px solid $shade-200;

    &.disabled {
      h5, p {
        opacity: 0.6;
      }
    }

    &.loaded {
      cursor: pointer;

      &:hover, &.active {
        background-color: $shade-200;

        h5 {
          color: var(--primary-900);
        }
      }
    }

    &.cursor-default {
      cursor: default;
    }

    @include media-breakpoint-down($bp-small-max) {
      @include box;
    }

    p {
      font-weight: 500;
      margin: 0;
    }

    .el-checkbox {
      margin-right: 0.75rem;
      margin-bottom: 0;
    }
  }

  // WorkDay
  .work-day {
    margin-bottom: 1rem;
    position: relative;
    background-color: $adm-white;
    transition: background-color 0.3s;
    box-shadow: $box-shadow-small;
    border-radius: 10px;
    border: 1px solid var(--shade-250, #EDEFF3);
    background: var(--white, #FFF);

    &.active {
      padding-bottom: 1rem;

      .work-day-header {
        span {
          color: $shade-900;
        }
      }
    }

    .work-day-header {
      display: flex;
      position: relative;
      align-items: center;
      height: 48px;
      padding: 8px 16px;
      background-color: $shade-200;
      justify-content: space-between;

      &__title{
        display: flex;

        svg {
          max-width: 20px;
          margin-right: 0.5rem;
          vertical-align: bottom;

          path {
            fill: $shade-500;
          }
        }

        span {
          color: $shade-900;
          line-height: 24px;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }

    .work-hours {
      display: flex;
      padding: 0 1rem 0.5rem;
    }

    .work-hours-header {
      display: flex;
      position: relative;
      border-bottom: 1px solid $shade-300;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;

      .column-title {
        flex: 1;
      }

      .category-title {
        line-height: 2rem;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0.5rem;

        > span {
          margin-left: 0.25rem;
        }
      }
    }

    .work-hours {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;

      .work-period {
        display: flex;
        padding: 0.75rem 0;
        position: relative;
        border-radius: 6px;
        cursor: default;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: $shade-200;
        }

        .actions {
          margin-left: 8px;

          .edit, .delete {
            cursor: pointer;
            margin-right: 0.25rem;
            transition: all 0.2s linear;

            &:hover {
              opacity: 0.8;
            }
          }
        }

        .name, .services, .location {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          flex: 1;
        }

        .services span {
          font-weight: 500;

          @include tablet-down {
            display: inline-block;
            width: 83%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .location {
          font-weight: 500;
        }

        .time {
          color: $shade-500;
          flex: 1;
        }

        span {
          color: $shade-900;
        }
      }
    }
  }

  // Status
  .status {
    text-transform: capitalize;

    &__icon {
      padding-right: 6px;
    }

    span.status-symbol {
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50%;
      border: 2px solid transparent;
      margin-right: 0.5rem;

      &.available, &.approved {
        border-color: $green-900;
      }

      &.pending {
        border-color: $yellow-900;
      }

      &.busy, &.day-off, &.break, &.rejected {
        border-color: $red-900;
      }

      &.canceled {
        border-color: $shade-900;
      }
    }
  }

  // uploaded photo
  .uploaded-photo {
    padding: 1rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  // No Animation on transitions
  .no-anim {
    display: none !important;
  }

  // Tooltip Icon
  .info-tooltip {
    vertical-align: text-top;
    width: 1rem;
    height: 1rem;

    path {
      fill: $shade-500;
    }
  }
}

// OUT OF #app
// Select Map Pins
.pin-icon {
  padding: 0.25rem 1rem;
  height: 40px;

  img {
    width: 15px;
    height: 32px;
    vertical-align: middle;
    padding: 2px 0;
    margin-right: 0.5rem;
  }
}

// Custom Scrollbar
.custom-scrollbar, .sidebar {
  &::-webkit-scrollbar {
    height: 5px;
    position: absolute;
    width: 5px;
    right: 1px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $shade-400;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $shade-500;
  }
}
