
          @import "@/assets/css/_variables.scss";
        

.adm-file-uploader {
  &__image {
    border: 1px dashed var(--primary-900);
    border-radius: 5px;
    padding: 8px;
    height: 132px;

    & > div {
      height: 100%;
    }

    .el-upload {
      display: flex;
      height: 100%;

      &__text {
        line-height: 20px;
        margin-top: 8px;
      }

      &-dragger {
        border-radius: 5px;
        border: none;
        background-color: var(--primary-300);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .uploaded-photo-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  &__actions {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
  }
}
