html, body, body input {
  margin: 0;
  padding: 0;
  font-size: $font-size;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $shade-900;
}

html, body, #app {
  min-height: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

p {
  font-size: calc(1rem - 2px);
}

p, div, a {
  font-size: calc(1rem - 2px);
  line-height: 1.5rem;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 0;
}

svg {
  outline: none;
}

a, a:visited, .link {
  color: var(--primary-900);
  cursor: pointer;
  text-decoration: none;

  svg {
    margin-right: 0.5rem;
    vertical-align: bottom;

    path {
      fill: var(--primary-900);
    }
  }
}

a:hover, .link:hover {
  text-decoration: underline;
}

h1 {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3rem;
}

h2 {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 500;
}

h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 500;
}

h5 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
}

h6 {
  font-size: calc(1rem - 2px);
  line-height: 1.5rem;
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

#app *:not(.material-icons) {
  font-family: $font-family;
}

.text-uppercase {
  text-transform: uppercase;
}

