@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.bubble.css';

//Override main wrapper background
#settings, #account-settings, #customize {
  .settings-form {
    height: 100%;

    > div {
      height: 100%;
      display: flex;
      flex-direction: column;

      .btns {
        margin-top: auto;
      }
    }
  }

  .btns {
    justify-content: flex-end;
  }

  //Settings Content
  .box {
    border-bottom: 1px solid $shade-300;
    margin-bottom: 1.5rem;

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }

    .box-title {
      margin-bottom: 1.5rem;

      img, svg {
        vertical-align: middle;
        margin-right: 1rem;
      }

      span {
        letter-spacing: 0.6px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $shade-600;
      }
    }
  }

  .section-title {
    margin-bottom: 1rem;

    img, svg {
      vertical-align: middle;
      margin-right: 1rem;
    }

    span {
      color: $shade-500;
      font-weight: 500;
    }
  }

  .head-row {
    padding: 0 0.5rem;
  }

  //Work Schedule
  .apply-hours {
    margin-bottom: 1rem;

    .align-right {
      span:first-child {
        margin-right: 1rem;
      }
    }

    @include media-breakpoint-down($bp-medium-max) {
      .align-right {
        text-align: left;
      }
    }
  }
}
