
          @import "@/assets/css/_variables.scss";
        

.social-media-images {
  &__desc {
    color: $shade-900;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__info {
    margin-bottom: 24px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;

    &__item {
      flex-basis: 33.33333%;
      display: flex;
      max-width: 138px;
      min-width: 138px;
      height: 220px;
    }
  }
}
