
          @import "@/assets/css/_variables.scss";
        

#createCustomer {
  .successfully-created {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
    text-align: center;
  }
}
