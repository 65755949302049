
          @import "@/assets/css/_variables.scss";
        

.adm--dialog {
  .change-number-of-users {
    box-shadow: 0 2px 7px -1px rgba(0, 0, 0, 0.12);
    border-radius: 5px;

    &__header {
      padding: 12px 16px;
      background-color: var(--primary-200);

      &__title {
        font-size: 16px;

        &__icon {
          height: 32px;
          width: 32px;
        }
      }

      &__price-interval {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &__price {
          font-size: 16px;
        }
      }
    }

    &__body {
      padding: 0 24px;
      overflow: hidden;

      &__row {
        &__title, &__title__text {
          font-size: 15px;
        }

        &__amount {
          font-size: 15px;
          color: var(--primary-900);
        }
      }
    }
  }
}
