
          @import "@/assets/css/_variables.scss";
        

.release-notes {
  box-shadow: 0 11px 16px rgb(0 13 39 / 10%), 0 2px 2px rgb(0 20 60 / 17%);
  border-radius: 7px;
  padding: 0;

  @include phone-down {
    width: 100% !important;
  }

  &__icon-unread {
    position: absolute;
    height: 16px;
    background-color: var(--primary-900);
    border-radius: 6px;
    top: 0;
    left: 20px;
    z-index: 1;

    &__count {
      position: absolute;
      width: 100%;
      height: 16px;
      color: $white;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }


  .el-tabs__header {
    margin: 0;
  }

  .custom-tabs-label {
    display: flex;
    align-items: center;

    &__count {
      align-items: center;
      display: flex;
      font-size: 12px;
      margin-left: 4px;
      padding: 4px;
      border-radius: 6px;
      background-color: var(--primary-400);
      color: var(--primary-1000);
      font-weight: 600;
      line-height: 16px;
      text-align: center;
      height: 16px;
    }
  }

  .single-tab {
    .el-tabs__item {
      color: $shade-900;
      font-size: 15px;
      font-weight: 500;
      padding: 12px !important;
    }

    .el-tabs__active-bar {
      display: none !important;
    }
  }

  .el-tabs__active-bar {
    width: 50% !important;
  }

  &.el-popper[x-placement^=bottom] {
    margin-top: 6px;

    @include phone-down {
      margin-top: 0;
      left: 0 !important;
      top: 56px !important;
    }
  }

  &-wrapper {
    position: relative;
  }

  &__header {
    padding: 12px;
    font-size: 15px;
    font-weight: 500;
    color: $shade-900;
    border-bottom: 1px solid $shade-300;
  }

  &__list {
    overflow-y: auto;
    max-height: 60vh;

    @media only screen and (max-width: 768px) {
      height: 100%;
      max-height: calc(100vh - 100px);
    }
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
  }

  &__show-more {
    width: 100%;
    height: 28px;
    padding: 4px 4px 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--primary-900);
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid $shade-250;
    position: absolute;
    bottom: 0;
    left: 0;
    background: white;
  }

  &__item {
    border-bottom: 1px solid $shade-250;
    transition: 0.3s;

    &.booking-alert {
      cursor: pointer;

      &:hover {
        background-color: var(--primary-300);
      }
    }

    &.new {
      background-color: var(--primary-200);
    }

    &:hover {
      background-color: $shade-150;
    }

    &__content {
      padding: 13px 16px 12px;

      &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        &__date {
          color: $shade-600;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
        }
      }

      &__title {
        font-weight: 500;
        word-break: break-word;
        font-size: 13px;
        color: $shade-900;
        text-align: left;
        line-height: 20px;
        margin-bottom: 2px;
      }

      &__description {
        font-size: 13px;
        color: $shade-700;
        line-height: 20px;
        text-align: left;
        word-break: break-word;
      }
    }
  }
}
