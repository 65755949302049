
          @import "@/assets/css/_variables.scss";
        

.pb-rich-text-wrapper {

  &.dark {
    color: $white;
  }

  .ql-align-right {
    text-align: right;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-justify {
    text-align: justify;
  }
  ol, ul {
    padding-left: 1.5rem;
    list-style-position: inside;
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  @for $i from 1 through 10 {
    .ql-indent-#{$i} {
      padding-left: 2rem * $i;
    }
  }
}
