
          @import "@/assets/css/_variables.scss";
        

.select-datetime {
  &__row {
    margin-top: -8px;

    &__col {
      margin-top: 8px;
    }
  }
}
