
          @import "@/assets/css/_variables.scss";
        

.main-nav-bar-mobile {
  &__wrapper {
    width: 100%;
    height: 66px;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-around;
    z-index: 10;
    bottom: 0;
    background: $adm-white;
    box-shadow: 0px -1px 1px rgba(0, 18, 79, 0.08);
  }
}
