
          @import "@/assets/css/_variables.scss";
        

// Input Wrapper
.adm-input-wrapper {
  width: 100%;
  position: relative;

  // Input & Textarea
  .adm-input, .adm-textarea {

    // Native Input & Native Textarea
    input, textarea {
      border: 1px solid $shade-300;
      border-radius: 7px;
      padding: 0 12px;
      font-size: 15px;
      -webkit-transition: box-shadow 0.15s;
      transition: box-shadow 0.15s;

      // Hover
      &:hover {
        box-shadow: inset 0 1px 2px rgba(20, 35, 61, 0.11);
      }

      // Active & Focus
      &:active, &:focus {
        border: 1px solid var(--primary-900);
        box-shadow: 0 1px 1px rgba(115, 134, 169, 0.06);
      }

      // Placeholder
      &::-webkit-input-placeholder, &:-ms-input-placeholder, &::placeholder {
        color: $shade-600;
      }
    }

    // Disabled
    &.is-disabled {
      input, textarea {
        background: $shade-150;
        border-color: $shade-300;
        box-shadow: none;
        cursor: not-allowed;
        color: $shade-600;

        // Placeholder
        &::-webkit-input-placeholder, &:-ms-input-placeholder, &::placeholder {
          color: $shade-450;
        }

        // Hover
        &:hover {
          box-shadow: unset;
        }

        // Active & Focus
        &:active, &:focus {
          border: 1px solid $shade-300;
          box-shadow: unset;
        }
      }
    }
  }

  // Input
  .adm-input {

    // Size
    &__size {

      // Default
      &__default {

        // Native Input
        input {
          height: 40px;
          line-height: 40px;
        }

        // Icon
        &.is-icon-start, &.is-icon-end {
          i {
            font-size: 24px;
            height: 24px;
            width: 24px;
          }
        }

        // Icon Start
        &.is-icon-start {

          // Prefix
          .el-input__prefix {
            left: 0.5rem;
          }

          // Native Input
          input {
            padding-left: 40px;
          }
        }

        // Icon End
        &.is-icon-end {

          // Suffix
          .el-input__suffix {
            right: 0.5rem;
          }

          // Native Input
          input {
            padding-right: 40px;
          }
        }
      }

      // Medium
      &__medium {

        // Native Input
        input {
          height: 36px;
          line-height: 36px;
        }

        // Icon
        &.is-icon-start, &.is-icon-end {
          i {
            font-size: 24px;
            height: 24px;
            width: 24px;
          }
        }

        // Icon Start
        &.is-icon-start {

          // Prefix
          .el-input__prefix {
            left: 6px;
          }

          // Native Input
          input {
            padding-left: 36px;
          }
        }

        // Icon End
        &.is-icon-end {

          // Suffix
          .el-input__suffix {
            right: 6px;
          }

          // Native Input
          input {
            padding-right: 36px;
          }
        }
      }

      // Small
      &__small {

        // Native Input
        input {
          height: 32px;
          line-height: 32px;
          font-size: 14px;
        }

        // Icon
        &.is-icon-start, &.is-icon-end {
          i {
            font-size: 20px;
            height: 20px;
            width: 20px;
          }
        }

        // Icon Start
        &.is-icon-start {

          // Prefix
          .el-input__prefix {
            left: 6px;
          }

          // Native Input
          input {
            padding-left: 32px;
          }
        }

        // Icon End
        &.is-icon-end {

          // Suffix
          .el-input__suffix {
            right: 6px;
          }

          // Native Input
          input {
            padding-right: 32px;
          }
        }
      }
    }

    // Icon Start & Icon End
    &.is-icon-start, &.is-icon-end {
      display: flex;
      align-items: center;

      // Icon
      i:before {
        color: $shade-900;
      }

      // Prefix & Suffix
      .el-input__prefix, .el-input__suffix, .el-input__suffix-inner {
        align-items: center;
        display: flex;
      }

      .with-handler {
        cursor: pointer;

        // Icon
        &:before {
          color: var(--primary-900);
        }
      }
    }
  }

  // Textarea
  .adm-textarea {

    // Size
    &__size {

      // Default
      &__default {

        // Native Textarea
        textarea {
          font-size: 15px;
          line-height: 24px;
          padding: 7px 12px;
        }
      }

      // Medium
      &__medium {

        // Native Textarea
        textarea {
          font-size: 15px;
          line-height: 24px;
          padding: 5px 10px;
        }
      }

      // Small
      &__small {

        // Native Textarea
        textarea {
          font-size: 14px;
          line-height: 20px;
          padding: 5px 10px;
        }
      }
    }
  }
}
