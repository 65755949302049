
          @import "@/assets/css/_variables.scss";
        

.share-appointment-entities-slots {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 4px;

  @include phone-up {
    gap: 18px;
  }

  &__date {
    margin-right: 6px;
  }

  &__time {
    max-width: 132px;
    width: 100%;

    @include phone-up {
      max-width: 100%;
      width: 100%
    }
  }
}
