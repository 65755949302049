
          @import "@/assets/css/_variables.scss";
        


// Plan Card
.adm-billing-dialog-plans-card {
  width: 285px;

  @include phone-down {
    width: 100%;
  }

  // Container
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $adm-white;
    box-shadow: 0 1px 1px rgba(0, 20, 60, 0.21), 0 2px 7px -1px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    padding: 24px 20px 24px;
    position: relative;

    &.is-blue {
      background: linear-gradient(180deg, #064EC6 0%, #000D27 100%);
      border-radius: 8px;
    }

    &__free-promo-discount {
      position: absolute;
      border-radius: 0 0 20px 0;
      background: #FFE600;
      padding: 6px 30px;
      left: 0;
      top: 0px;
      color: var(--primary-900);
      font-size: 14px;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: 0.28px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    // Active Plan
    &__active-plan {
      position: absolute;
      right: 20px;
      top: 14px;
      color: $green-900;

      &.is-blue {
        color: $adm-white;
      }
    }

    // Active
    &.is-active {
      box-shadow: 0 0 0 1px var(--primary-900);
    }

    // Best Value Plan
    &__best-value {
      display: none;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: -40px;
      right: 0;
      z-index: 3;

      @include tablet-up {
        display: flex;

        &:lang(it) {
          width: 100%;
        }
      }

      &__tooltip {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__bullet {
          display: flex;
          height: 10px;
        }

        &__frame {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 3px 12px;
          background: var(--primary-400);
          border-radius: 36px;
          border: 1px solid var(--primary-900);
          margin-bottom: 20px;

          &__content {
            color: var(--primary-900);
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            text-align: center;

            &:lang(it) {
              font-size: 13px;
            }
          }
        }
      }
    }

    svg {

    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__wrapper:last-child {
    flex: 1;
  }

  // Name
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $shade-900;
    margin-bottom: 4px;

    &.promo-billing-2024 {
      justify-content: center;
      padding: 12px 12px 0 12px;
      flex-wrap: wrap;
      flex-direction: column;
    }

    &__name {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;

      &.promo-billing-2024 {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.24px;
        color: $shade-900;
      }

      &.is-blue {
        color: $adm-white ;
      }
    }

    &__black-friday-users {
      font-size: 14px;
      font-weight: 450;
      line-height: 22px;
    }

    &.is-blue {
      color: $adm-white;
    }

    // Saving
    &__badge {
      display: flex;
      padding: 4px 8px;
      background: $yellow-400;
      border-radius: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;

      &--active {
        background: $green-400;
        color: $green-900;
      }

      &--saving {
        color: $yellow-1000;

        &.is-blue {
          background: rgba(0, 20, 60, 0.3);
        }
      }
    }
  }

  &__price-section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include phone-up {
      min-height: 76px;
    }

    &.promo-billing-2024 {
      min-height: unset;
      margin: 10px auto;
    }

    &.promo-billing-2024-free {
      min-height: 143px;
      margin: 0 auto;
    }

    &.promo-billing-2024-free-tax {
      margin-top: 20px;
      min-height: 95px;
    }

    &.promo-billing-2024-enterprise-tax {
      margin-top: 20px;
      min-height: 115px;
    }

    &.promo-billing-2024-enterprise {
      min-height: 99px;
      margin-top: 15px;
    }

    &__starting {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: var(--primary-900);

      &.promo-billing-2024 {
        margin-top: 30px;
        font-weight: 450;
        font-size: 16px;
        text-align: center;
      }
    }

    &__value {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      justify-content: flex-start;

      &.promo-billing-2024-free {
        margin-top: 35px;
      }

      &.promo-billing-2024-free-tax {
        margin-top: 30px;
      }

      &.promo-billing-2024 {
        position: relative;
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        color: $adm-white;
        margin: 0 auto;
        align-items: baseline;
        padding: 2px 28px;
      }

      // Price
      &__price {
        display: flex;
        font-weight: 600;
        font-size: 28px;
        text-align: center;
        letter-spacing: 0.01em;
        color: var(--primary-900);
        margin-right: 4px;
        line-height: 125%;
        align-items: baseline;

        &.is-blue {
          color: $adm-white;
        }

        &__interval {
          color: var(--primary-900);
          display: flex;
          align-items: end;
          font-weight: 500;
          font-size: 17px;
          letter-spacing: 0.34px;
          line-height: 130%;

          &.is-blue {
            color: $adm-white;
          }
        }

        &.promo-billing-2024 {
          font-size: 42px;
          letter-spacing: 0.42px;
        }

        &.promo-billing-2024-discount {
          color: var(--primary-900);

          &.is-blue {
            color: $adm-white;
          }
        }

        &.promo-billing-2024-discount-price {
          font-size: 28px;
          align-self: center;
        }
      }
    }

    // Tax
    &__tax {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      text-align: left;
      color: rgba(0, 20, 60, 0.70);
      font-style: italic;
      text-transform: uppercase;

      &.promo-billing-2024 {
        text-align: center;
        margin-top: 4px;
      }

      &.is-blue {
        color: rgba(255, 255, 255, 0.70);
      }
    }
  }

  // Billed annually
  &__billed {
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: var(--primary-900);

    &.is-blue {
      color: $adm-white;
    }
  }

  // Coupon
  &__coupon {

    &__tag {
      background: #FF00E5;
      border: 1px solid #FF00E5;
      box-shadow: 0 0 10px #FB36FF;
      color: $adm-white;
      text-transform: uppercase;
      font-weight: 600;
      text-align: center;
      padding: 5px 10px;
    }

    &__without-coupon {
      position: relative;
      text-align: center;


      &.promo-billing-2024-discount-price {
        font-size: 20px;
      }

      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        right: 0;
        border-top: 1px solid;
        border-color: inherit;
        opacity: 1;
        color: #005AEE;
      }

      &.is-blue:before  {
        color: $adm-white;
      }

      span {
        font-size: 24px;
        font-weight: 450;
        color: rgba(0, 20, 60, 0.70);
        line-height: normal;

        &.is-blue {
          color: rgba(255, 255, 255, 0.70);
        }
      }

      &.is-blue {
        color: $adm-white;
      }
    }
  }

  // Status
  &__status {
    text-align: center;
    margin-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 12px;
    border-bottom: 1px solid $shade-300;

    &__button {
      width: 100%;

      &__users-number {
        max-width: 100%;
      }
    }

    // Status Content
    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 120px;
    }
  }

  // Includes
  &__includes {
    &__title {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 12px;

      &.is-blue {
        color: $adm-white
      }
    }

    &__item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 0.25rem;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-weight: normal;
        margin-left: 0.25rem;
        font-size: 13px;
        line-height: 24px;

        &.is-blue {
          color: var(--primary-400);
        }
      }
    }
  }
}
