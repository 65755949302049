
          @import "@/assets/css/_variables.scss";
        

// Billing Dialog Plans
.ad-billing-dialog-plans {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  // Header
  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    margin-top: 64px;

    @include tablet-down {
      flex-direction: column;
    }

    @include phone-down {
      margin-top: 44px;
      gap: 24px;
    }

    &__expired {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 24px;
      color: $shade-900;
      line-height: 24px;
      margin-bottom: 48px;

      &__icon {
        width: 30px;
        height: 30px;
        margin-right: 10px;

        & path {
          fill: $red-900;
        }
      }
    }

    // Black friday illustration
    &__bf {
      position: absolute;
      top: -120px;
      width: 100%;
      justify-content: center;
      display: flex;

      &__image-container {
        max-width: 50%;
        margin-bottom: -65px;

        img {
          max-width: 100%;
        }
      }
    }

    // Title
    &__title {
      width: 100%;
      margin-bottom: 16px;

      @include tablet-up {
        display: flex;
      }

      &__select {
        flex: 1;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        text-align: center;

        @include tablet-up {
          text-align: left;
        }

        &__trafft {
          color: var(--primary-900);

          &__plan {
            color: var(--primary-900);
            text-transform: lowercase;
          }
        }
      }

      @include phone-down {
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
      }
    }

    // Billing Interval
    &__interval {
      height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-right: auto;
      margin-left: auto;

      @include tablet-down {
        flex-direction: column-reverse;
        width: 100%;
        margin-top: 55px;
        margin-right: auto;
        margin-left: auto;
      }

      // Discount Tip
      &__discount {
        position: absolute;
        left: 260px;
        display: flex;
        height: 24px;
        align-items: center;

        @include tablet-up {
          &:lang(sr) {
            left: 369px;
          }

          &:lang(nl) {
            left: 389px;
          }

          &:lang(it) {
            left: 349px;
          }

          &:lang(cs) {
            left: 299px;
          }

          &:lang(pt-BR) {
            left: 359px;
          }

          &:lang(es) {
            left: 389px;
          }

          &:lang(fr) {
            left: 379px;
          }

          &:lang(de) {
            left: 429px;
          }

          &:lang(ru-RU) {
            left: 299px;
          }

          &:lang(tr) {
            left: 369px;
          }

          &:lang(th) {
            left: 349px;
          }

          &:lang(sv) {
            left: 339px;
          }
        }

        @include tablet-down {
          flex-direction: row-reverse;
          margin-right: 0;
          position: relative;
          left: unset;
          margin-bottom: 8px;
        }

        span {
          font-weight: 600;
          font-size: 15px;
          line-height: 16px;
          color: var(--primary-900);
          white-space: nowrap;
        }

        &__arrow {
          background: url("~@/assets/img/billing/billing-arrow-desktop.svg");
          height: 25px;
          width: 43px;

          @include tablet-down {
            margin-top: 14px;
            margin-left: 6px;
            background: url("~@/assets/img/billing/billing-arrow-mobile.svg");
            height: 19px;
            width: 27px;
          }
        }
      }

      &__switch {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        height: 24px;

        .el-switch__label--left {
          text-align: right;
        }
      }
    }
  }

  // Plans
  &__plans {
    padding: 32px 0 64px 0;
    border-bottom: 1px solid $shade-300;
    gap: 20px;
    justify-content: center;

    @include tablet-down {
      padding-top: 16px;
      gap: 16px;
    }

    &__compare {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      width: 100%;
      margin-top: 28px;

      @include phone-down {
        margin-top: 0;
      }
    }
  }

  // Footer
  &__footer {

    // Links
    &__links {
      a {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
      }

      span {
        margin: 0 0.75rem;
        color: $shade-500;
      }
    }
  }

  &__secure-policy {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 32px;
    flex-wrap: wrap;
    margin-top: 44px;

    @include phone-down {
      flex-direction: column;
      margin-top: 0;
    }

    &__refund {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px 24px;
      gap: 10px;
      background: url("~@/assets/img/billing/refund-bg.png");
      background-size: cover;
      border-radius: 16px;
      flex: 1;

      &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.01em;
        color: $adm-white;
      }

      &__description {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: $shade-250;
      }
    }

    &__secure {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      flex: 1;

      &__purchase {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        gap: 16px;
        border: 1px solid $shade-400;
        border-radius: 16px;

        &__desc {
          font-weight: 700;
          font-size: 18px;
          line-height: 120%;
          color: $black;
          opacity: 0.9;
        }
      }

      &__icons {
        display: flex;
        align-items: flex-start;
        gap: 8px;
      }
    }
  }
}
