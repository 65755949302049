
          @import "@/assets/css/_variables.scss";
        

// Main Header
.main-header {
  position: fixed;
  background-color: #FBFBFC;
  width: calc(100% - 72px);
  height: 60px;
  z-index: 100;
  box-shadow: 0 1px 1px rgba(0, 18, 79, 0.08);
  padding: 0 0 0 1.25rem;
  display: flex;
  align-items: center;

  // Tablet Down
  @include tablet-down {
    background-color: var(--main-navigation-color);
    height: 56px;
    width: 100%;
    padding-left: 8px;
    z-index: 999;

    // Logo
    &__logo {
      height: 40px;
      width: 40px;

      // Image
      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
      }
    }
  }

  &__left {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  // Booking Website
  &__booking-website {
    margin-right: 8px;

    @include tablet-down {
      display: none;
    }

    // Popover
    &__popover {
      padding: 1rem;
      box-shadow: $box-shadow-dropdown;

      &.el-popper[x-placement^=bottom] {
        margin-top: 4px;
      }

      // Buttons
      &__buttons {
        width: 100%;
      }
    }
  }

  // Customize
  &__customize {
    margin-right: 24px;
  }

  // Button Icons
  &__icon-buttons {
    margin-left: auto;
    order: 2;
    height: 100%;
    align-items: center;
    display: flex;
    padding: 8px 16px;
    border-left: 1px solid $shade-300;
    gap: 16px;

    &--agency {
      display: none;

      @include tablet-down {
        display: flex;
      }
    }

    // Tablet Down
    @include tablet-down {
      margin-left: auto;
      border: none;
      padding: 0;
    }

    // Help
    &__help {

      // Note
      &__note {
        max-width: 224px;
        padding: 4px 0;
        border-top: 1px solid $shade-300;

        // Text
        &__text {
          padding: 0.25rem 0.5rem;
          opacity: 0.67;
          font-style: italic;
          font-size: 12px;
          line-height: 16px;
        }
      }

      // Version
      &__version {
        padding-top: 4px;
        border-top: 1px dashed $shade-300;

        // Text
        &__text {
          padding: 0.25rem 0.5rem;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    // Button
    .adm-button {
      color: $shade-800;

      i {
        @include tablet-down {
          color: $adm-white;
        }

        &.tz-cog, &.tz-question-mark-circle-outline, &.tz-activity {
          font-size: 28px;
        }
      }

      @include tablet-down {
        &:hover, &:active, &.is-active {
          background: $shade-700;
        }
      }
    }

    &__booking-website {
      &.adm-button {
        display: none;
        @include tablet-down {
          display: flex;
        }
        background-color: var(--primary-900);

        &:hover {
          background: var(--primary-800);
        }

        &:focus:not(:active), &.is-focused {
          background: var(--primary-900);
          border: 2px solid $adm-white;
          box-shadow: 0 0 0 2px var(--primary-900);
        }

        // Active
        &:active {
          background: var(--primary-1000);
        }
      }
    }

    &__share-link {
      &.adm-button {
        display: none;
        @include tablet-down {
          display: flex;
        }
        background-color: #8E42D1;

        &:hover {
          background: #7235A7;
        }

        &:focus:not(:active), &.is-focused {
          background: #7235A7;
          border: 2px solid $adm-white;
          box-shadow: 0 0 0 2px #7235A7;
        }

        // Active
        &:active {
          background: #55287D;
        }
      }
    }
  }

  // Account
  &__account {
    cursor: pointer;
    order: 4;
    height: 100%;
    align-items: center;
    display: flex;

    // Slot
    &__slot {
      display: flex;
      align-items: center;
      outline: none;
      padding: 0 1rem;
      height: 100%;
      width: 100%;
      border-left: 1px solid $shade-300;
      border-right: 1px solid transparent;

      @include tablet-down {
        border-left: 1px solid transparent;
      }

      // Hover
      &:hover {
        background: $shade-200;
        transition: background-color 0.2s ease;

        @include tablet-down {
          background: $shade-700;
        }
      }

      // Focus
      &:focus:not(:active):not(.is-active) {
        border: 1px solid var(--primary-900);
      }

      // Active
      &:active, &.is-active {
        background: $shade-250;

        @include tablet-down {
          background: $shade-700;
        }
      }

      // Name
      &__name {
        margin: 0 4px 0 12px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
      }

      // Arrow
      &__arrow {
        path {
          fill: $shade-500;
        }
      }
    }
  }

  // Main Navigation Bar Expanded
  &.is-main-navigation-bar-expanded {
    width: calc(100% - 84px);

    @include tablet-down {
      width: 100%;
    }
  }
}
