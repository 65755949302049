
          @import "@/assets/css/_variables.scss";
        

// Design System Attributes Table
.adm-design-system-documentation-table {
  margin-bottom: 2rem;

  // Title
  &__title {
    margin-bottom: 1rem;
  }

  // Cell
  .cell {
    word-break: break-word;
  }
}
