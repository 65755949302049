
          @import "@/assets/css/_variables.scss";
        

.share-appointment-entities {
  &__extra {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__quantity {
      margin-left: 8px;
      flex-shrink: 0;
    }
  }

  &__date-options {
    &__date-range {
      margin-right: 12px;
    }
  }
}
