
          @import "@/assets/css/_variables.scss";
        

// Billing Dialog Free Downgrade
.ad-billing-dialog-free-downgrade {
  width: 368px;
  background: $adm-white;
  margin: 1.25rem auto 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  // Body
  &__body {
    padding: 0 48px;
  }

  // Icon
  &__icon {
    display: flex;
    justify-content: center;
    padding-top: 1.5rem;
  }

  // Title
  &__title {
    margin-top: 1rem;
    padding-bottom: 1.25rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    text-align: center;
  }

  // Subtitle
  &__subtitle {
    font-weight: 500;
    display: flex;
    text-align: center;
    padding-bottom: 1.5rem;
    opacity: 0.8;
  }

  // Actions
  &__actions {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    gap: 16px;
    flex-wrap: wrap;
  }

  // Footer
  &__footer {
    border-top: 1px solid $shade-300;
    padding: 18px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    // Trafft
    &__trafft {
      font-weight: normal;
      font-size: 14px;
      color: $shade-500;
      margin-bottom: 0.5rem;
    }

    // Links
    &__links {
      font-weight: normal;
      display: flex;

      span {
        text-align: center;
        color: $shade-500;
        margin: 0 0.75rem;
      }
    }
  }
}
