
          @import "@/assets/css/_variables.scss";
        

.main-nav-bar__item {
  display: flex;
  height: 48px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:focus {
    outline: none;
    border: none;
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    flex-direction: column;
    text-align: center;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.07);
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.1);

    path.customize-fill {
      fill: $adm-white;
      opacity: 1;
    }
  }

  span {
    margin-top: 2px;
    display: none;
    color: $adm-white;
    opacity: 0.85;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  }

  svg {
    margin: 0 auto;

    path.customize-fill {
      fill: $adm-white;
      opacity: 0.55;
    }
  }
}
