
          @import "@/assets/css/_variables.scss";
        

// Button Group
.adm-button-group {
  display: flex;
  align-items: center;

  // Button
  .adm-button {

    // One Button Next To Another
    + .adm-button {
      margin-left: 0;
    }

    // First Button
    &:first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;

      // Filled Grey
      &.adm-button__filled__grey {

        // Focus
        &:focus:not(:active), &.is-focused {
          + .adm-button {
            border-left: 1px solid var(--primary-900);
          }
        }
      }
    }

    &:not(:first-child):not(:last-child) {
      &.adm-button {
        border-radius: 0;
      }
    }
    &:not(:first-child) {
      &.adm-button {
        &:not(.adm-button__ghost__blue) {
          border-left: 1px solid transparent;
        }
      }
    }

    // Last Button
    &:last-child {
      margin: 0;
      border-radius: 0 7px 7px 0;

      // Filled Blue
      &.adm-button__filled__blue {
        border-left: 1px solid rgba($adm-white, 0.3);

        // Focus
        &:focus:not(:active), &.is-focused {
          background-color: var(--primary-900);
          border: 2px solid $adm-white;
          box-shadow: 0 0 0 2px var(--primary-900);

          // Icon
          i {
            margin-left: 1px;
          }
        }
      }

      // Filled Grey
      &.adm-button__filled__grey {
        border-left: 1px solid $shade-400;

        // Focus
        &:focus:not(:active), &.is-focused {
          border: none;

          // Icon
          i {
            margin-left: 1px;
          }
        }
      }
    }
  }

  // One Button Group Next To Another
  + .adm-button-group, + .adm-button {
    margin-left: 12px;
  }
}
