
          @import "@/assets/css/_variables.scss";
        

// Dashboard Top Alert
.adm-alert-top {
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  gap: 10px;

  @include phone-up {
    padding: 8px 12px;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    align-self: start;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;

      // Title
      &__title {
        margin-right: auto;
        color: $adm-white;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
      }

      // Icon Wrapper
      &__icon-wrapper {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 20px;
          display: block;
        }
      }
    }

    &__close.adm-button {
      display: flex;

      @include phone-up {
        display: none;
      }
    }
  }

  // Default
  &__default {
    background-color: $shade-200;
  }

  // Positive
  &__positive {
    background-color: var(--primary-400);

    i:before {
      color: var(--primary-900);
    }
  }

  // Warning
  &__warning {
    background-color: $yellow-800;

    .adm-alert-top__content__left__title {
      color: $shade-900;
    }
  }

  // Danger
  &__danger {
    background-color: $red-900;
    color: $adm-white;

    i:before {
      color: $adm-white;
    }
  }

  &__right {
    width: 100%;
    display: flex;
    justify-content: end;

    @include phone-up {
      width: initial;
    }

    .adm-button {
      width: 100%;

      @include phone-up {
        width: initial;
      }
    }

    // Close
    &__close.adm-button {
      display: none;
      margin-left: 8px;

      @include phone-up {
        display: flex;
      }
    }
  }
}
