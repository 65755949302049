
          @import "@/assets/css/_variables.scss";
        

// Input Number Wrapper
.adm-input-number-wrapper {
  width: 100%;

  // Input Number
  .adm-input-number {

    // Size
    &__size {

      // Default
      &__default {

        // Decrease & Increase
        .el-input-number__decrease, .el-input-number__increase {
          height: 28px;
          width: 28px;
        }

        // Decrease
        .el-input-number__decrease {
          top: 6px;
          left: 6px;
        }

        // Increase
        .el-input-number__increase {
          top: 6px;
          right: 6px;
        }

        // Input
        .el-input__inner {
          padding-left: 40px;
          padding-right: 40px;
          font-size: 15px;
          line-height: 24px;
          color: $shade-900;
        }
      }

      // Medium
      &__medium {

        // Decrease & Increase
        .el-input-number__decrease, .el-input-number__increase {
          height: 24px;
          width: 24px;
        }

        // Decrease
        .el-input-number__decrease {
          top: 6px;
          left: 6px;
        }

        // Increase
        .el-input-number__increase {
          top: 6px;
          right: 6px;
        }

        // Input
        .el-input__inner {
          padding-left: 36px;
          padding-right: 36px;
          font-size: 15px;
          line-height: 24px;
          color: $shade-900;
        }
      }

      // Small
      &__small {

        // Decrease & Increase
        .el-input-number__decrease, .el-input-number__increase {
          height: 24px;
          width: 24px;
        }

        // Decrease
        .el-input-number__decrease {
          top: 4px;
          left: 4px;
        }

        // Increase
        .el-input-number__increase {
          top: 4px;
          right: 4px;
        }

        // Input
        .el-input__inner {
          padding-left: 32px;
          padding-right: 32px;
          font-size: 14px;
          line-height: 20px;
          color: $shade-900;
        }
      }
    }
  }

  // Element Input Number
  .el-input-number {
    width: 100%;

    // Decrease & Increase
    &__decrease, &__increase {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $adm-white;
      border-radius: 4px;

      // Hover - Input Rules
      &:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled):not(:focus) {
        box-shadow: inset 0 1px 2px rgba(20, 35, 61, 0.11);
        border-color: $shade-300;
      }

      // Hover - Button Rules
      &:hover:not(.is-disabled) {
        background-color: $shade-250;
      }

      // Active
      &:active:not(.is-disabled) {
        background-color: $shade-300;
      }

      // Icon
      i {
        font-size: 20px;

        &:before {
          color: $shade-700;
        }
      }

      // Disabled
      &.is-disabled i:before {
        color: $shade-400;
      }
    }

    // Decrease
    &__decrease {
      border-right: 0;
    }

    // Increase
    &__increase {
      border-left: 0;
    }

    // Input
    .el-input__inner {
      cursor: text;
      border-radius: 7px;
      border: 1px solid $shade-300;
      -webkit-transition: box-shadow 0.15s;
      transition: box-shadow 0.15s;

      // Hover
      &:hover {
        box-shadow: inset 0 1px 2px rgba(20, 35, 61, 0.11);
      }

      // Focus
      &:focus {
        border: 1px solid var(--primary-900);
        box-shadow: 0 1px 1px rgba(115, 134, 169, 0.06);
      }
    }

    // Disabled
    &.is-disabled {

      // Decrease & Increase
      .el-input-number__decrease, .el-input-number__increase {
        background-color: $shade-150;

        // Hover
        &:hover ~ .el-input .el-input__inner {
          box-shadow: none;
          border-color: $shade-300;
        }

        // Icon
        i:before {
          color: $shade-400;
        }
      }

      // Input
      .el-input__inner {
        cursor: not-allowed;
        background-color: $shade-150;
        color: $shade-600;

        // Hover
        &:hover {
          box-shadow: none;
        }

        // Focus
        &:focus {
          border-color: $shade-300;
          box-shadow: none;
        }
      }
    }
  }
}
