
          @import "@/assets/css/_variables.scss";
        

.active-popover-border {
  border: 2px solid #00edfc;

  &.adm-button__size__small {
    padding: 0 14px;
  }

  &--rounded {
    border-radius: 5px;
  }
}
.active-popover {
  box-shadow: 0 0 0 2px #00edfc;

  &--rounded {
    border-radius: 50%;
  }
}

.guide__item {

  &__popover {
    padding: 0;

    .el-popover {
      color: $shade-900;

      &__title {
        display: inline-block;
        font-weight: 600;
        font-size: 18px;
        padding: 24px 28px 0;
        word-break: break-word;
        text-align: left;
      }
    }

    &__description {
      word-break: normal;
      text-align: left;
      padding-left: 28px;
      padding-right: 28px;
      padding-bottom: 12px;
      &--proceed {
        font-weight: 500;
      }
    }

    &__close-button {
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 6px;
    }

    &--smaller {
      .el-popover {
        &__title {
          padding-left: 16px;
          padding-top: 12px;
          margin-bottom: 8px;
          font-size: 16px;
        }
      }
      .guide__item__popover__description {
        padding-left: 16px;
        padding-right: 20px;
      }
    }

    &__actions {
      margin-top: 16px;
      padding: 10px 16px;
      border-top: 1px solid $shade-300;
    }
  }
}
