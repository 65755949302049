
          @import "@/assets/css/_variables.scss";
        

// Billing Dialog Free Chosen
.ad-billing-dialog-free-chosen {
  width: 368px;
  background: $adm-white;
  margin: 1.25rem auto 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  // Body
  &__body {
    padding: 0 24px;
  }

  // Icon
  &__icon {
    display: flex;
    justify-content: center;
    padding-top: 2rem;

    svg {
      width: 100px;
      height: 100px;
    }
  }

  // Title
  &__title {
    margin-top: 1rem;
    padding-bottom: 1.25rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    text-align: center;
    border-bottom: 1px solid $shade-300;
  }

  // Includes
  &__includes {
    padding-top: 1.25rem;
    align-items: center;

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 0.25rem;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-weight: normal;
        margin-left: 0.25rem;
        font-size: 13px;
      }

      svg {
        flex-shrink: 0;
      }
    }
  }

  // Alert
  &__alert {
    margin-top: 0.75rem;
  }

  // Close (Go to Trafft)
  &__close {
    margin: 1.25rem 0 1.5rem;

    button {
      width: 100%;
    }
  }

  // Footer
  &__footer {
    border-top: 1px solid $shade-300;
    padding: 18px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    // Trafft
    &__trafft {
      font-weight: normal;
      font-size: 14px;
      color: $shade-500;
      margin-bottom: 0.5rem;
    }

    // Links
    &__links {
      font-weight: normal;
      display: flex;

      span {
        text-align: center;
        color: $shade-500;
        margin: 0 0.75rem;
      }
    }
  }
}
