
          @import "@/assets/css/_variables.scss";
        

// Billing Dialog Deactivate Wrapper
.ad-billing-dialog-deactivate-wrapper {
  width: 484px;
  margin: 1.25rem auto 0;

  @include phone-down {
    width: auto;
  }

  // Title
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  // Subtitle
  &__subtitle {
    margin-top: 0.5rem;
    font-weight: 500;
    opacity: 0.8;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid $shade-300;
    margin-bottom: 1rem;
  }

  // Active Count
  &__active-count {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;

    // Headline
    &__headline {
      font-weight: 500;
    }

    // Number
    &__number {
      display: flex;
      align-items: center;
      margin-left: auto;
      order: 2;
      font-weight: 500;
      font-size: 18px;

      &__icon {
        display: flex;
        align-items: center;
      }

      span {
        min-width: 40px;
        text-align: right;
      }

      &.error {
        color: $red-900;
      }
    }
  }

  // Item
  &__item {
    padding: 1rem 1.25rem;
    background: $adm-white;
    box-shadow: 0 1px 1px rgba(0, 20, 60, 0.21), 0 2px 7px -1px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    position: relative;

    &.disabled :before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba($adm-white, 0.1);
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    // Icon
    &__icon {
      display: flex;
      margin-right: 1rem;

      img {
        height: 40px;
        width: 40px;
      }
    }

    // Name
    &__name {
      font-weight: 600;
      font-size: 18px;
      max-width: 333px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // Switch
    &__switch {
      margin-left: auto;
      order: 2;
    }
  }

  // Continue
  &__continue {
    margin-top: 1.25rem;
  }

  // Back
  &__back {
    margin-top: 0.75rem;

    a {
      display: inline-flex;
      align-items: center;
    }
  }
}
