/* montserrat-300 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-300italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: local(''),
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-regular - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local(''),
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-500 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-500italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: local(''),
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-600 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-600italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: local(''),
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-700 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-700italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local(''),
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-800 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local(''),
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-800italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: local(''),
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-800italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-900 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: local(''),
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-900italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: local(''),
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/montserrat/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
