
          @import "@/assets/css/_variables.scss";
        

.black-friday-promotion {
  &__countdown {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 24px;

    @media screen and (min-width: 769px) {
      height: 80px;
      width: 154px;
      background: #05011A;
      border-radius: 8px;
      padding: 16px;
    }

    @include phone-down {
      justify-content: flex-start;
    }

    &__holder {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 4px;

      &__days, &__hours, &__minutes, &__seconds {
        height: 29px;
        text-align: center;
        color: #FFFFFF;
        font-size: 24px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        gap: 8px;

        @media screen and (min-width: 769px) and (max-width: 1199px) {
          padding: 3px 0 6px 0;
          font-size: 24px;
          line-height: 24px;
        }

        @media screen and (max-width: 768px) {
          padding: 4px 5px 1px 5px;
          font-size: 24px;
          line-height: 24px;
          height: 24px;

          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1px;
        }
      }

      &__text {
        text-align: center;
        font-weight: 400;
        color: #FFF;
        font-size: 10px;
        line-height: 10px;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &__mobile-text {
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        align-self: flex-end;

        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }
  }
}
