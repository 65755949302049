
          @import "@/assets/css/_variables.scss";
        

.guide-success-modal {

  .el-dialog {

    &__header {
      display: none;
    }

    width: 485px;
    position: fixed;
    top: 26px;
    right: 40px;
    display: block;
  }

  &__image {
    display: flex;
    margin: 20px auto 20px 0;
    vertical-align: middle;
    text-align: center;
    position: relative;
  }

  &__content {
    background-color: $adm-white;
    word-break: normal;
    font-size: 18px;
    z-index: 999;
    padding-left: 0;

    &__title {
      flex-wrap: wrap;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: .0125em;
      line-height: 2rem;
      padding-left: 0;
      padding-top: 1rem;
      display: flex;
      align-items: center;
      position: relative;

      button {
        position: absolute;
        right: 0;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 22px;
      padding: .5rem 0 1rem;

      p {
        padding-bottom: 0;
      }
    }
  }

  &__guide-actions {
    margin-bottom: 16px;
    padding-left: 0;
    padding-top: 0;
    &__icon {
      vertical-align: middle;
      height: 18px;
      margin-left: 10px;
      path {
        fill: $shade-900;
      }
    }
  }
}
