
          @import "@/assets/css/_variables.scss";
        

.price-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 12px;

  @include phone-down {
    position: fixed;
    top: 56px;
    bottom: 66px;
    height: auto;
    left: 0;
    right: 0;
    padding: 20px;
    background-color: #fff;
    z-index: 3;
    overflow: auto;
  }

  &__title {
    margin-bottom: 25px;

    a {
      font-size: 18px;
      color: $shade-900;
    }
  }

  .customers-label {
    font-size: 12px;
    color: $shade-600;
    margin-bottom: 5px;
  }

  .customer-section {
    margin-bottom: 20px;

    .customer {
      &__general-info {
        border-bottom: 1px dashed $shade-300;
        padding: 2px 0;

        &__row {
          display: flex;
          justify-content: space-between;
        }
      }

      &__name {
        font-weight: 600;
      }

      &__extras {
        border-bottom: 2px solid $shade-300;

        &__extra {
          display: flex;
          align-items: center;
          padding: 8px 0;

          &__name {
            color: $shade-600;
          }

          &__price {
            margin: 0 0 0 auto;
          }
        }
      }

      &__taxes {
        padding: 8px 0 0 0;
        display: flex;
        justify-content: space-between;
      }

      &__recurring {
        padding-top: 8px;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
      }

      &__total {
        padding-top: 8px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__total-price {
    display: flex;
    justify-content: space-between;
    padding: 10px 12px;
    border-radius: 5px;
    background-color: var(--primary-300);
    margin: auto 0 0 0;

    .price {
      color: var(--primary-900);
    }
  }
}
