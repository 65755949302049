//  Message
.el-message {
  padding: 0.5rem 2.75rem 0.5rem 0.75rem;
  border-radius: 7px;
  box-shadow: 0 2px 3px rgba($shade-900, 0.18);
  border: 0;
  min-width: initial;
  display: flex;
  align-items: flex-start;

  @include tablet-down {
    width: 65%;
    top: 65px !important;
  }

  @include phone-down {
    width: 90%;
  }

  // Content
  &__content {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-right: 0.5rem;
  }

  // Closable
  &.is-closable {

    // Content
    &__content {
      padding-right: 1.25rem;
    }
  }

  // Icon
  .el-message__icon {
    margin-right: 0.25rem;
    font-size: 24px;

    &:before {
      display: inline-block;
      font-family: 'icomoon' !important;
      content: " ";
    }
  }

  // Close Icon
  .el-icon-close {
    position: absolute;
    display: inline-block;
    font-size: 24px;
    width: 24px;
    height: 24px;
    top: 8px;
    right: 12px;
    transform: none;

    &:before {
      font-family: 'icomoon' !important;
      content: "\e910";
    }
  }

  // Success
  &.el-message--success {
    background-color: $green-900;

    p {
      color: $shade-900;
    }

    // Icon Success
    .el-message__icon:before {
      content: "\e90a";
    }

    // Icon Colors
    i:before {
      color: $shade-900;
    }
  }

  // Warning
  &.el-message--warning {
    background-color: $yellow-900;

    p {
      color: $shade-900;
    }

    // Icon Warning
    .el-message__icon:before {
      content: "\e917";
    }

    // Icon Colors
    i:before {
      color: $shade-900;
    }
  }

  // Info
  &.el-message--info {
    background-color: $shade-900;

    p {
      color: $adm-white;
    }

    // Icon Info
    .el-message__icon:before {
      content: "\e929";
    }

    // Icon Colors
    i:before {
      color: $adm-white;
    }
  }

  // Error
  &.el-message--error {
    background-color: $red-900;

    p {
      color: $adm-white;
    }

    // Icon Error
    .el-message__icon:before {
      content: "\e94a";
    }

    // Icon Colors
    i:before {
      color: $adm-white;
    }
  }
}
