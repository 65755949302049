
          @import "@/assets/css/_variables.scss";
        

.slide-guide {

  &__content {
    background-color: $adm-white;
    width: 850px;

    &__description {
      word-break: normal;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 14px;
    }

    &__tutorial {
      min-height: 330px;
      img, video {
        width: 100%;
      }
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &__continue {
      margin-left: auto;
    }
  }
}

