/* maven-pro-regular - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('~@/assets/font/maven-pro/maven-pro-v25-vietnamese_latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/maven-pro/maven-pro-v25-vietnamese_latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* maven-pro-500 - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('~@/assets/font/maven-pro/maven-pro-v25-vietnamese_latin-ext_latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/maven-pro/maven-pro-v25-vietnamese_latin-ext_latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* maven-pro-600 - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('~@/assets/font/maven-pro/maven-pro-v25-vietnamese_latin-ext_latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/maven-pro/maven-pro-v25-vietnamese_latin-ext_latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* maven-pro-700 - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('~@/assets/font/maven-pro/maven-pro-v25-vietnamese_latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/maven-pro/maven-pro-v25-vietnamese_latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* maven-pro-800 - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 800;
  src: local(''),
  url('~@/assets/font/maven-pro/maven-pro-v25-vietnamese_latin-ext_latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/maven-pro/maven-pro-v25-vietnamese_latin-ext_latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* maven-pro-900 - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 900;
  src: local(''),
  url('~@/assets/font/maven-pro/maven-pro-v25-vietnamese_latin-ext_latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/font/maven-pro/maven-pro-v25-vietnamese_latin-ext_latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
