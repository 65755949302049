
          @import "@/assets/css/_variables.scss";
        

// Design System Colors Page
.adm-design-system-colors {
  display: flex;

  // Row
  &__row {
    display: block;

    h3 {
      margin-bottom: .5rem;
    }
  }

  // Variable
  &__variable {
    font-weight: 500;
  }


  // Block
  &__block {
    display: block;
    width: 200px;
    height: 40px;
    margin-right: 2rem;
    border-radius: 8px;
    margin-bottom: 0.25rem;
  }
}
