
          @import "@/assets/css/_variables.scss";
        

.total-price {
  @include phone-down() {
    padding-top: 16px;
    border-top: 1px solid $shade-300;
  }

  .price-title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;

    &__text {
      font-weight: 600;
    }
  }

  .price-block {
    border-top: 1px solid $shade-300;
    height: 40px;
    display: flex;
    align-items: center;

    &:last-of-type {
      border-bottom: 1px solid $shade-300;
    }

    span {
      font-weight: 500;
    }

    .price-amount {
      position: absolute;
      right: 0;
    }
  }
}
