
          @import "@/assets/css/_variables.scss";
        

.main-nav-bar {
  width: 72px;
  padding-top: var(--alert-height);
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background: var(--main-navigation-color);
  overflow: auto;
  transition: all 0.3s ease;

  // Logo
  &__logo {
    height: 40px;
    width: 40px;
    margin: 10px 0 0 0;

    // Image
    img {
      width: inherit;
      height: inherit;
      object-fit: contain;
    }
  }

  // Expand
  &__expand {
    width: 68px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 12px 0;
    cursor: pointer;
    border-radius: 3px;
    gap: 12px;

    div {
      display: flex;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  // Top Menu
  &__top {
    width: 100%;
  }

  // Bottom Menu
  &__bottom {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  // Expanded
  &.is-expanded {
    width: 84px;

    // Item
    .main-nav-bar__item {
      height: 60px;

      span {
        display: block;
        padding: 0 4px;
      }
    }
  }
}
