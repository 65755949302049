
          @import "@/assets/css/_variables.scss";
        

// Actions Dropdown
.adm-actions-dropdown {
  cursor: pointer;

  // Three Dots
  &__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;

    // Icon
    path {
      fill: $shade-500;
    }

    // Icon Hover
    &:hover {
      path {
        fill: var(--primary-900);
      }
    }
  }
}
