
          @import "@/assets/css/_variables.scss";
        

.main-nav-bar-mobile__more-item {
  a {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    text-decoration: none;

    span {
      color: $shade-900;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      margin-left: 10px;
      border-bottom: 1px solid $shade-300;
    }

    i:before {
      font-size: 20px;
      color: $shade-800;
    }
  }

  &.active {
    span, i:before {
      color: var(--primary-900);
    }
  }

  &.is-red {
    span, i:before {
      color: $red-900;
    }
  }

  &.is-disabled {
    span, i:before {
      color: $shade-600;
    }
  }
}
