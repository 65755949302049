
          @import "@/assets/css/_variables.scss";
        

.adm-drawer-mobile {
  &__wrapper {
    z-index: 9;
    position: fixed;
    bottom: 66px;
    left: 0;
    right: 0;

    &.cover-content {
      bottom: 0;
      z-index: 10;
    }
  }

  & {
    width: 100%;
    background-color: $adm-white;
    box-shadow: 0px -1px 1px rgba(0, 20, 60, 0.06), 0px -3px 19px rgba(0, 20, 60, 0.08);
    border-radius: 8px 8px 0 0;
    padding: 16px;
    position: relative;
    z-index: 2;

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      &__title {
        font-size: 20px;
        line-height: 28px;
      }

      &__close {
        cursor: pointer;
      }
    }

    &__overlay {
      z-index: 1;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba($shade-900, 0.1);
    }
  }

  .main-nav-bar-mobile__more-item {
    &:last-of-type {
      span {
        border: none;
      }
    }
  }
}
