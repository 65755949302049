
          @import "@/assets/css/_variables.scss";
        

.more-items {
  padding: 0.25rem;
  background-color: var(--primary-400);
  color: var(--primary-900);
  margin-left: 0.5rem;
  font-size: 12px;
  font-weight: 600 !important;
  border-radius: 3px;
  vertical-align: middle;
  line-height: 16px;
  width: 32px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
}
