
          @import "@/assets/css/_variables.scss";
        

.adm-square-uploader {
  &__image {
    border: 1px dashed $shade-500;
    border-radius: 8px;
    padding: 8px;
    height: 132px;
    max-width: 100%;

    &:hover {
      border-color: var(--primary-900);

      .tz-cloud-upload-outline {
        &::before {
          color: var(--primary-900);
        }
      }
    }

    & > div {
      height: 100%;
    }

    .el-upload {
      display: flex;
      height: 100%;

      &__text {
        line-height: 20px;
        margin-top: 8px;
        padding: 0 10px;

        em {
          font-weight: 500;
        }
      }

      .el-upload-dragger {
        border-radius: 5px;
        border: none;
        background-color: var(--primary-300);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .el-upload__text {
          em {
            color: var(--primary-900);
          }
        }
      }
    }

    .uploaded-photo-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  &__actions {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
  }
}
