
          @import "@/assets/css/_variables.scss";
        

.manage-appointment-info {
  .section {
    padding: 0 1rem 0 2rem;
    margin-bottom: 1rem;

    &:not(:first-child) {
      border-top: 1px solid $shade-300;
      padding-top: 1rem;
    }

    .section-text {
      color: $shade-900;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      opacity: 0.8;
    }
  }

  .section-title {
    margin-bottom: 0.5rem;

    span {
      color: $shade-900;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      opacity: 0.8;
    }

    i {
      margin-left: -2rem;
      margin-right: 8px;
    }
  }

  .create-appointment {
    position: absolute;
    width: calc(100% - 2rem);
    bottom: 1.5rem;
    right: 0;

    .el-button {
      width: 100%;
    }
  }

  .placeholder {
    display: flex;
    justify-content: center;
    padding-top: 16px;

    > div {
      max-width: 252px;
    }

    img {
      display: flex;
      justify-content: center;
      width: 240px;
    }

    &__text {
      text-align: center;
      margin-top: 1rem;
      color: $shade-900;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
    }

    p {
      margin-top: 0.5rem;
      color: $shade-700;
      text-align: center;
    }
  }

  .bottom-block-container {
    margin: auto 0 0;
    width: 100%;
    display: grid;
  }
}
