
          @import "@/assets/css/_variables.scss";
        

// Menu
.adm-menu {
  border-right: 1px solid $shade-300;
  padding-right: 1rem;
  margin-right: 1.75rem;
  height: 100%;

  // Element Menu
  .el-menu {
    border: none;
  }

  // Horizontal
  &--horizontal {
    border-right: none;
    padding-right: 0;
    margin-right: 0;
    border-bottom: 1px solid $shade-300;
    height: auto;

    // Menu
    .el-menu {
      display: flex;
    }

    // Menu Item
    li.adm-menu-item.el-menu-item {
      border-radius: 0;
      color: $shade-900;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      justify-content: center;
      padding: 12px 1rem !important;
      height: 44px;
      border-bottom: none;

      @include phone-down {
        flex: 1 0 50%;
      }

      // Hover
      &:hover {
        background-color: var(--primary-400);
        color: $shade-900;
      }

      // Active
      &.is-active:not(.is-with-floating) {
        background-color: $adm-white;
        box-shadow: none;
        color: var(--primary-900);
        border-bottom: none;

        &:after {
          content: "";
          width: 100%;
          height: 3px;
          background-color: var(--primary-900);
          left: 0;
          position: absolute;
          display: block;
          bottom: -1px;
        }
      }

      &.is-active-with-floating {
        background-color: $adm-white;
        box-shadow: none;
        color: var(--primary-900);
        border-bottom: none;

        &:after {
          content: "";
          width: 100%;
          height: 3px;
          background-color: var(--primary-900);
          left: 0;
          position: absolute;
          display: block;
          bottom: -1px;
        }
      }
    }
  }
}
