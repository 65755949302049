
          @import "@/assets/css/_variables.scss";
        

.service-card {
  padding: 0.5rem;
  border: 1px solid transparent;
  box-shadow: $box-shadow-small;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    border-color: var(--primary-900);
  }

  &.selected {
    border-color: transparent;
    cursor: auto;
  }

  .service-card-name {
    font-weight: 600;
    margin: 0;
    display: flex;
    align-items: center;
    color: $shade-900;
    overflow: hidden;
  }

  .service-card-details {
    text-align: right;
    display: flex;
    gap: 8px;
    margin: 0 0 0 auto;

    span {
      font-size: 12px;
      color: $shade-900;
      opacity: 0.8;
      text-align: right;
    }
  }

  .service-card-close {
    text-align: right;

    i {
      cursor: pointer;
      font-weight: 700;
      color: $shade-500;
    }
  }
}
