
          @import "@/assets/css/_variables.scss";
        

// Recurring
.customer-card-recurring {
  border-top: 1px solid $shade-300;
  padding: 0.75rem 1.25rem;
  gap: 16px;

  &__form-item-enabled {
    margin-bottom: 16px;
  }

  &__container {
    padding: 12px;
    border-radius: 8px;
    background-color: var(--primary-200);

    &__frequency {
      padding-top: 0;
      border-bottom: 1px solid $shade-250;
      align-items: center;

      &--no-recurrence {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    &__times {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 500;
      margin-bottom: 16px;
      padding-top: 16px;

      &__form-item {
        margin-bottom: 0;

        ::v-deep {
          .adm-input-number-wrapper {
            min-width: 84px;
            max-width: 100px;
          }
        }

        ::v-deep > div {
          display: flex;
          align-items: center;
        }
      }
    }

    &__week-repeat {
      border-bottom: 1px solid $metal;
      margin-bottom: 18px;

      @media (max-width: 380px) {
        margin-bottom: 8px;
      }

      &__frequency {
        display: flex;
        flex-wrap: wrap;

        @include phone-down {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 8px;
          margin-bottom: 16px;
        }

        &__option {
          margin-bottom: 16px;
          padding: 12px 16px;
          font-size: 14px;
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.13), 0 2px 4px rgba(0, 0, 0, 0.04);
          border-radius: 6px;
          margin-right: 8px;
          cursor: pointer;
          user-select: none;
          text-align: center;
          background-color: $white;

          &.selected {
            background-color: var(--primary-500);
          }

          @include phone-down {
            margin-right: 0;
            margin-bottom: 0;
          }

          &:hover:not(.selected):not(.disabled) {
            background-color: $shade-250;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.13), 0 2px 4px rgba(0, 0, 0, 0.04);
          }

          &.disabled {
            cursor: not-allowed;
            background-color: $shade-250;
            color: $shade-700;
          }
        }
      }
    }

    &__month-repeat {
      padding-bottom: 16px;
      border-bottom: 1px solid $shade-250;
      margin-bottom: 16px;
    }

    &__limits {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      background-color: var(--primary-400);
      border-radius: 8px;
      padding: 8px 8px 8px 16px;
      font-weight: 500;

      &__values {
        display: flex;
        font-weight: 400;
        text-transform: uppercase;

        &__item {
          display: flex;
          align-items: center;
          border-radius: 8px;
          color: $shade-600;
          font-weight: 500;
          background-color: $white;
          padding: 4px 4px 4px 8px;

          &:not(:last-child) {
            margin-left: 4px;
            margin-right: 8px;
          }

          &__value {
            font-weight: 600;
            font-size: 22px;
            line-height: 28px;
            margin-left: 8px;
            color: $shade-900;
          }
        }
      }
    }

    &__generate {
      display: flex;
      justify-content: flex-end;
    }

    &__list {
      background-color: var(--primary-200);
      margin-bottom: 16px;

      &--collapsed {
        margin-bottom: 0;
      }

      &__info {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $shade-250;
        padding-bottom: 16px;
        margin-bottom: 12px;

        &--collapsed {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }

        &__repeat {
          display: flex;
          align-items: center;
          text-transform: lowercase;

          &__icon {
            margin-right: 8px;
          }
        }

        a {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: var(--primary-900);
        }
      }

      &__wrapper {
        background-color: $white;
        padding: 8px 12px;

        &--hidden {
          display: none;
        }
      }
    }
  }

  .recurring-list-transition {
    backface-visibility: hidden;
    z-index: 1;

    &-enter-active, &-leave-active {
      transition: all 0.5s ease;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }
  }
}
