
          @import "@/assets/css/_variables.scss";
        

// Radio Wrapper
.adm-radio-wrapper {

  // Element Radio
  .el-radio {
    display: flex;
    align-items: center;
    white-space: normal;

    // Inner
    &__inner {
      border: 1px solid $shade-450;
      height: 16px;
      width: 16px;
    }

    // Input
    &__input {
      padding: 4px 0;
      display: flex;
      align-items: center;

      // Checked
      &.is-checked {

        // Inner
        .el-radio__inner {
          border-color: var(--primary-900);
          background: var(--primary-900);
        }

        // Disabled
        &.is-disabled {

          // Inner
          .el-radio__inner {
            border-color: var(--primary-600);
            background: var(--primary-600);

            // After
            &:after {
              background: $adm-white;
            }
          }
        }
      }
    }

    // Label
    &__label {
      display: inline-block;
      padding-left: 0.75rem;
    }

    // Selected Label
    .el-radio__input.is-checked:not(.is-disabled) + .el-radio__label {
      color: $shade-900;
    }

    // Hover
    &:hover:not(.is-disabled):not(.is-checked) {

      // Inner
      .el-radio__inner {
        border: 1px solid $shade-600;
        box-shadow: inset 0 1px 1px rgba(20, 35, 61, 0.11);
      }
    }

    // Focus
    &:focus:not(.is-focus):not(:active):not(.is-disabled) {

      // Inner
      .el-radio__inner {
        border: 1px solid var(--primary-900);
        box-shadow: 0 0 0 2px var(--primary-500);
      }
    }

    // Disabled
    &.is-disabled {

      // Inner
      .el-radio__inner {
        border: 1px solid $shade-400;
        box-shadow: 0 1px 1px rgba(115, 134, 169, 0.06);
        background-color: $shade-150;
      }

      // Label
      .el-radio__label {
        color: $shade-700;
      }
    }

    // Label Right
    &.is-label-right {

      // Input
      .el-radio__input {
        margin-left: auto;
        order: 2;
      }

      // Label
      .el-radio__label {
        padding-left: 0;
        padding-right: 8px;
      }
    }

    .adm-radio__label {
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
    }

    // With Description
    &.is-with-description {
      align-items: flex-start;

      // Description
      .adm-radio__description {
        color: $shade-700;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
}
