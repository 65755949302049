
          @import "@/assets/css/_variables.scss";
        

// Customer Card
.customer-card {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  background: $adm-white;
  border-radius: 5px;
  margin-top: 0.75rem;

  // Info
  &__info {
    padding: 13px 1rem;
    background: $shade-200;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    // Name
    &__name {
      font-weight: 600;
    }
  }

  // Actions
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid $shade-300;
    flex-wrap: wrap;
    gap: 8px;

    @include phone-down {
      flex-direction: column;
      align-items: flex-start;
    }

    &__email {
      flex-grow: 1;
    }

    &__select-boxes {
      display: flex;
      justify-content: flex-end;
      flex: 1;

      &__status {
        width: 125px;
      }

      &__persons {
        width: 100px;
      }
    }
  }

  &__extras {
    padding: 12px 16px;

    &__selected-extras {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 8px;
    }
  }

  // Custom Fields
  &__custom-fields {
    border-top: 1px solid $shade-300;
    padding: 0.75rem 1.25rem;

    .adm-file-uploader {
      margin-bottom: 35px;
    }

    ::v-deep .adm-file-uploader__image {
      width: 246px;
      height: 108px;
      margin-bottom: 20px;
    }

    ::v-deep .el-upload-list__item {
      max-width: 246px;
      width: 246px;
      margin-top: 20px;
      left: -10px;
    }
  }

  // Coupon
  &__coupon {
    border-top: 1px solid $shade-300;
    padding: 0.75rem 1.25rem;

    &__container {
      display: flex;
      align-items: flex-end;
      gap: 16px;

      &--info-presented {
        align-items: center;
      }

      &__form-item {
        width: 100%;
        margin-bottom: 0;

        ::v-deep .adm-form-item__description {
          color: $green-900;
        }
      }
    }

    &__info {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      margin-top: 4px;
    }
  }
}
