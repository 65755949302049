.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.d-block {
  display: block !important;
}

.float-right {
  float: right;
}

.align-right {
  text-align: right;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.align-item-end {
  display: flex;
  align-items: flex-end;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}

.flex {
  display: flex;
  flex: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.box-shadow {
  box-shadow: $box-shadow-page-content;
  border: solid 1px $shade-200;
  border-radius: 6px;
  background-color: $adm-white;
}

.input-icon {
  vertical-align: text-top;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.break-word {
  word-break: break-word;
}

.round-40 {
  border-radius: 50%;
  min-width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radius-40 {
  border-radius: 5px;
  min-width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.round-24 {
  border-radius: 50%;
  min-width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radius-24 {
  border-radius: 5px;
  min-width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.big-font {
  font-size: 2rem;
}

.va-middle {
  display: inline-block;
  vertical-align: middle;
}

.va-sub {
  display: inline-block;
  vertical-align: sub;
}

.va-baseline {
  display: inline-block;
  vertical-align: middle;
}

.ultra-bold {
  font-weight: 700 !important;
}

.bold {
  font-weight: 600 !important;
}

.semi-bold {
  font-weight: 500 !important;
}

.light-background {
  background-color: $shade-200;
}

.light-text {
  color: $shade-500;
}

.shade-800-text {
  color: $shade-800;
}

.tablet-visible {
  display: none;
  @include tablet-down {
    display: block;
  }
}

.mobile-visible {
  display: none;
  @include media-breakpoint-down($bp-small-max) {
    display: block;
  }
}

.tablet-hidden {
  @include tablet-down {
    display: none;
  }
}

.mobile-hidden {
  @include media-breakpoint-down($bp-small-max) {
    display: none;
  }
}

.mb-8-md {
  margin-bottom: 0 !important;
  @include media-breakpoint-down($bp-large-min) {
    margin-bottom: 8px !important;
  }
}
.gap-2 {
  gap: 0.125rem;
}

.gap-4 {
  gap: 0.25rem;
}

.gap-8 {
  gap: 0.5rem;
}

.gap-12 {
  gap: 0.75rem;
}

.gap-16 {
  gap: 1rem;
}

.gap-24 {
  gap: 1.5rem;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.m-4 {
  margin: 0.25rem !important;
}

.mt-4,
.my-4 {
  margin-top: 0.25rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 0.25rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 0.25rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 0.25rem !important;
}

.mt-5,
.my-5 {
  margin-top: 5px;
}

.mb-4,
.my-4 {
  margin-bottom: 5px;
}

.m-8 {
  margin: 0.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 0.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 0.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 0.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 0.5rem !important;
}

.m-10 {
  margin: 10px !important;
}

.mt-10,
.my-10 {
  margin-top: 10px !important;
}

.mr-10,
.mx-10 {
  margin-right: 10px !important;
}

.mb-10,
.my-10 {
  margin-bottom: 10px !important;
}

.ml-10,
.mx-10 {
  margin-left: 10px !important;
}

.m-12 {
  margin: 0.75rem !important;
}

.mt-12,
.my-12 {
  margin-top: 0.75rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 0.75rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 0.75rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 0.75rem !important;
}

.m-16 {
  margin: 1rem !important;
}

.mt-16,
.my-16 {
  margin-top: 1rem !important;
}

.mr-16,
.mx-16 {
  margin-right: 1rem !important;
}

.mb-16,
.my-16 {
  margin-bottom: 1rem !important;
}

.ml-16,
.mx-16 {
  margin-left: 1rem !important;
}

.m-20 {
  margin: 1.25rem !important;
}

.mt-20,
.my-20 {
  margin-top: 1.25rem !important;
}

.mr-20,
.mx-20 {
  margin-right: 1.25rem !important;
}

.mb-20,
.my-20 {
  margin-bottom: 1.25rem !important;
}

.ml-20,
.mx-20 {
  margin-left: 1.25rem !important;
}

.m-24 {
  margin: 1.5rem !important;
}

.mt-24,
.my-24 {
  margin-top: 1.5rem !important;
}

.mr-24,
.mx-24 {
  margin-right: 1.5rem !important;
}

.mb-24,
.my-24 {
  margin-bottom: 1.5rem !important;
}

.ml-24,
.mx-24 {
  margin-left: 1.5rem !important;
}

.m-28 {
  margin: 1.75rem !important;
}

.mt-28,
.my-28 {
  margin-top: 1.75rem !important;
}

.mr-28,
.mx-28 {
  margin-right: 1.75rem !important;
}

.mb-28,
.my-28 {
  margin-bottom: 1.75rem !important;
}

.ml-28,
.mx-28 {
  margin-left: 1.75rem !important;
}

.m-32 {
  margin: 2rem !important;
}

.mt-32,
.my-32 {
  margin-top: 2rem !important;
}

.mr-32,
.mx-32 {
  margin-right: 2rem !important;
}

.mb-32,
.my-32 {
  margin-bottom: 2rem !important;
}

.ml-32,
.mx-32 {
  margin-left: 2rem !important;
}

.m-48 {
  margin: 3rem !important;
}

.mt-48,
.my-48 {
  margin-top: 3rem !important;
}

.mr-48,
.mx-48 {
  margin-right: 3rem !important;
}

.mb-48,
.my-48 {
  margin-bottom: 3rem !important;
}

.ml-48,
.mx-48 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-4 {
  padding: 0.25rem !important;
}

.pt-4,
.py-4 {
  padding-top: 0.25rem !important;
}

.pr-4,
.px-4 {
  padding-right: 0.25rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 0.25rem !important;
}

.pl-4,
.px-4 {
  padding-left: 0.25rem !important;
}

.p-8 {
  padding: 0.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 0.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 0.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 0.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 0.5rem !important;
}

.p-12 {
  padding: 0.75rem !important;
}

.pt-12,
.py-12 {
  padding-top: 0.75rem !important;
}

.pr-12,
.px-12 {
  padding-right: 0.75rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 0.75rem !important;
}

.pl-12,
.px-12 {
  padding-left: 0.75rem !important;
}

.p-16 {
  padding: 1rem !important;
}

.pt-16,
.py-16 {
  padding-top: 1rem !important;
}

.pr-16,
.px-16 {
  padding-right: 1rem !important;
}

.pb-16,
.py-16 {
  padding-bottom: 1rem !important;
}

.pl-16,
.px-16 {
  padding-left: 1rem !important;
}

.p-20 {
  padding: 1.25rem !important;
}

.pt-20,
.py-20 {
  padding-top: 1.25rem !important;
}

.pr-20,
.px-20 {
  padding-right: 1.25rem !important;
}

.pb-20,
.py-20 {
  padding-bottom: 1.25rem !important;
}

.pl-20,
.px-20 {
  padding-left: 1.25rem !important;
}

.p-24 {
  padding: 1.5rem !important;
}

.pt-24,
.py-24 {
  padding-top: 1.5rem !important;
}

.pr-24,
.px-24 {
  padding-right: 1.5rem !important;
}

.pb-24,
.py-24 {
  padding-bottom: 1.5rem !important;
}

.pl-24,
.px-24 {
  padding-left: 1.5rem !important;
}

.p-32 {
  padding: 2rem !important;
}

.pt-32,
.py-32 {
  padding-top: 2rem !important;
}

.pr-32,
.px-32 {
  padding-right: 2rem !important;
}

.pb-32,
.py-32 {
  padding-bottom: 2rem !important;
}

.pl-32,
.px-32 {
  padding-left: 2rem !important;
}

.p-48 {
  padding: 3rem !important;
}

.pt-48,
.py-48 {
  padding-top: 3rem !important;
}

.pr-48,
.px-48 {
  padding-right: 3rem !important;
}

.pb-48,
.py-48 {
  padding-bottom: 3rem !important;
}

.pl-48,
.px-48 {
  padding-left: 3rem !important;
}

//Mixins
@mixin box {
  border-radius: 8px;
  border: 1px solid $shade-300;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
}

// Text Colors
.text-error {
  color: $red-900 !important;
}

// Width
.w1000 {
  max-width: 1000px;
  margin: 0 auto;
}

.w1200 {
  max-width: 1200px;
  margin: 0 auto;
}

.w1560 {
  max-width: 1560px;
  margin: 0 auto;
}

.max-width-100 {
  max-width: 100%;
}

.width-100 {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}

.required:after {
  content:" *";
  color: $red-900;
}

// Transitions
// Enter Transition
.adm-fade-transition-enter {
  opacity: 0;
}

// Enter Active Transition
.adm-fade-transition-enter-active {
  transition: opacity 0.5s;
}

// Leave Active Transition
.adm-fade-transition-leave-active {
  transition: opacity 0s;
  opacity: 0;
}

.body--no-scroll {
  overflow: hidden;
}
