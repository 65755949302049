
          @import "@/assets/css/_variables.scss";
        

.recurring-item-card {
  font-weight: 500;
  position: relative;
  padding-left: 8px;
  padding-right: 8px;

  &--edit {
    background-color: $shade-150;
  }

  &:not(:first-child) {
    box-shadow: inset 0 1px 0 0 $shade-250;
  }

  &:hover {
    background-color: $shade-150;
    border-radius: 6px;
    box-shadow: none;

    &+div {
      box-shadow: none;
    }

    @include tablet-up {
      .recurring-item-card__actions {
        display: flex;
        align-items: center;
      }
    }
  }

  &__data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
  }

  &__info {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-top: 8px;
    padding-bottom: 8px;

    &__date {
      display: inline-block;
      min-width: 100px;
      color: $shade-900;
      margin-right: 8px;

      &--warning {
        color: $yellow-1000;
      }
    }

    &__time {
      margin-right: 12.5px;
      color: $shade-600;

      &--warning {
        color: $yellow-1000;
      }
    }
  }

  &__actions {
    display: none;
    gap: 4px;
    justify-content: end;

    @include phone-up {
      gap: 8px;
    }

    &--edit {
      display: flex;
      flex-wrap: wrap;
    }

    &-mobile {
      margin-right: 4px;

      @include tablet-up {
        display: none;
      }

      &--edit {
        display: none;
      }
    }
  }

  &__edit {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding-bottom: 8px;

    @include tablet-up {
      flex-wrap: nowrap;
    }

    & > div {
      flex-basis: 100%;
    }
  }
}
