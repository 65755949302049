
          @import "@/assets/css/_variables.scss";
        

.adm-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &__small {
    font-size: 18px;
  }

  &__medium {
    font-size: 20px;
  }

  &__big {
    font-size: 24px;
  }

  &__large {
    font-size: 32px;
    width: 32px;
    height: 32px;
  }

  &__extraLarge {
    font-size: 40px;
    width: 40px;
    height: 40px;
  }

  &__grey {
    color: $shade-900;

    &:before {
      color: $shade-900;
    }
  }

  &__primary {
    color: var(--primary-900);

    &:before {
      color: var(--primary-900);
    }
  }

  &__warning {
    color: $yellow-900;

    &:before {
      color: $yellow-900;
    }
  }

  &__danger {
    color: $red-900;

    &:before {
      color: $red-900;
    }
  }

  &:before {
    color: inherit;
  }
}
