
          @import "@/assets/css/_variables.scss";
        

.one-row-form-item {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  gap: 22px;

  @include tablet-down {
    display: block;
  }

  &.with-border-bottom {
    border-bottom: 1px solid $shade-200;
  }

  &.with-border-top {
    border-top: 1px solid $shade-200;
  }

  &__left {
    width: 100%;

    & > * {
      margin-bottom: 0;
    }

    &__description {
      font-size: 13px;
      line-height: 20px;
      color: $shade-700;
      vertical-align: top;
      padding-bottom: 4px;

      & a {
        font-size: 13px;
      }
    }

    &__label {
      padding-bottom: 4px;
      vertical-align: top;
      display: inline-flex;
      line-height: 20px;
    }
  }

  &__right {
    display: flex;
    gap: 20px;
    flex-shrink: 0;

    & > * {
      width: 100%;
      margin-bottom: 0;
    }
  }
}
