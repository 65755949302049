//Single Employee
#employee {
  .assigned-services {
    margin-bottom: 2rem;

    .assigned-services-header {
      position: relative;
      border-bottom: 1px solid $shade-300;
      padding: 0 0.5rem 0.5rem;

      .adm-switch-header {
        display: block;

        @include media-breakpoint-up($bp-medium-max) {
          display: none;
        }
      }

      .category-title {
        line-height: 2rem;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0.5rem;

        @include tablet-down {
          display: flex;
        }

        > span {
          margin-left: 0.25rem;
        }
      }

      .adm-switch {
        position: absolute;
        right: 4px;
      }

      .column-title {
        display: flex;

        @include media-breakpoint-down($bp-medium-max) {
          display: none;
        }
      }

      span {
        color: $shade-500;
        font-weight: 600;
      }
    }

    .assigned-services-row {
      padding: 0.75rem;
      border-radius: 6px;
      position: relative;

      .assigned-services-row-card {
        @include media-breakpoint-down($bp-medium-max) {
          border-radius: 8px;
          border: 1px solid var(--shade-300, #DEE1E6);
          padding: 12px;
        }
      }

      .assigned-services-row-card-col {
        @include tablet-down {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        span.assigned-services-row-card-col-name {
          color: $shade-600;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          display: flex;

          @include media-breakpoint-up($bp-medium-max) {
            display: none;
          }
        }

        .adm-input-wrapper, .adm-input-number-wrapper {
          @include tablet-down {
            max-width: 140px;;
            margin-left: 4px;
          }
        }
      }

     .el-col {
        margin-bottom: 12px;

       @include media-breakpoint-up($bp-medium-max) {
          margin: 0;
        }
      }

      .initial {
        min-width: 2rem;
        min-height: 2rem;
        line-height: 2rem;
        background-color: var(--primary-900);
        color: $adm-white;
      }

      .service-title {
        display: flex;
        align-items: center;
        line-height: 2rem;
        color: $shade-900;
        font-weight: 600;

        .service-name {
          width: 90%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.is-disabled {
          .service-name {
            color: $shade-500;
          }
        }
      }

      .column-title {
        display: none;
        margin: 0.75rem 0 0.25rem;
        @include media-breakpoint-down($bp-medium-max) {
          display: block;
        }

        .el-tooltip {
          margin-left: 0.5rem;
          color: $shade-500;
        }
      }

      span {
        font-weight: 600;
      }

      .adm-input-number-wrapper {
        min-width: 78px;
      }

      .adm-switch-wrapper {
        justify-content: end;

        @include phone-up {
          justify-content: flex-start;
        }
      }

      .el-switch {
        position: relative;

        @include media-breakpoint-up($bp-medium-max) {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
        }
      }
    }
  }

  .work-days {
    padding-bottom: 1rem;
  }
}
