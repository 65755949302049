
          @import "@/assets/css/_variables.scss";
        

.shoplazza-back-ribbon {
  padding: 8px 24px 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $shade-150;

  &__info {
    display: none;
    align-items: center;
    order: 3;
    gap: 12px;
    color: $shade-250;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;

    @include phone-up {
      display: flex;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}
