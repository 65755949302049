
          @import "@/assets/css/_variables.scss";
        

// Popover
.adm-popover {
  border-radius: 8px;
  filter: drop-shadow(0px 11px 16px rgba(0, 13, 39, 0.1)) drop-shadow(0px 2px 2px rgba(0, 20, 60, 0.17));
  padding: 16px;
  font-size: 14px;
  line-height: 20px;
  color: $shade-800;

  // Title
  .el-popover__title {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  // Close
  &__close {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}
