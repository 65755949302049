
          @import "@/assets/css/_variables.scss";
        

// Design System Element Block
.adm-design-system-element-block {
  margin-bottom: 2rem;

  // Last Element
  &:last-of-type {
    margin-bottom: 0;
  }

  // Title Wrapper
  &__title-wrapper {
    margin-bottom: 1rem;
  }

  // Title
  &__title {
    margin-bottom: 0.5rem;
  }

  // Container
  &__container {
    border-radius: 4px;
    box-shadow: $box-shadow-large;
  }

  // Preview
  &__preview {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid $shade-300;
  }

  // Code
  &__code {
    padding: 1.5rem;

    // Description
    &__description {
      border: 1px solid $shade-300;
      border-radius: 4px;
      padding: 1rem;
      margin-bottom: 1rem;
    }

    // Preformatted Text
    pre.language-markup {
      &:before, &:after {
        content: none;
      }

      code {
        box-shadow: none;
        padding: 8px;
        border-left: 3px solid $red-900;
        background: #f4f4f4;
      }
    }
  }
}
