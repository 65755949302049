
          @import "@/assets/css/_variables.scss";
        

.share-appointment-details-item {
  display: flex;
  margin-bottom: 8px;
  flex: 100%;

  @include phone-up {
    flex: 50%;
  }

  &__icon {
    margin-right: 8px;
  }

  &__title {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
  }

  &__value {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }
}
