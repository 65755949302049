
          @import "@/assets/css/_variables.scss";
        

.adm-radio-group-wrapper {
  .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__block {
    flex-direction: column;
  }
}
