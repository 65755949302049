#customers {
  .customers-list {
    .name-email {
      overflow: hidden;

      div {
        color: $shade-500;
        margin-right: 0.5rem;
      }
    }
  }

  .row.active {
    .customer-name {
      color: var(--primary-900);
    }
  }
}

#customer {
  .customer-tabs {
    padding: 2rem 2rem 1rem 2rem;
    @include phone-down {
      padding: 1rem;
      margin-bottom: 68px;
    }
  }
}
