/* SELECTBOX FLAGS */
.am-flag-option {
  display: flex;
  align-items: center;
}

.am-flag {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url('~@/assets/img/flags/all.png') no-repeat;
  float: left;
  margin: 0 10px 0 0;
}

.am-flag.am-flag-auto {
  background-image: url('~@/assets/img/flags/auto.png');
}

.am-flag.am-flag-ar {
  background-position: -216px 0;
}

.am-flag.am-flag-pg, .am-flag.am-flag-pgk {
  background-position: -216px -240px;
}

.am-flag.am-flag-cm {
  background-position: -264px -48px;
}

.am-flag.am-flag-gn {
  background-position: -24px -120px;
}

.am-flag.am-flag-ee {
  background-position: -264px -72px;
}

.am-flag.am-flag-ga {
  background-position: -192px -96px;
}

.am-flag.am-flag-tt {
  background-position: -168px -312px;
}

.am-flag.am-flag-gg {
  background-position: -288px -96px;
}

.am-flag.am-flag-vg {
  background-position: -72px -336px;
}

.am-flag.am-flag-cv {
  background-position: 0 -72px;
}

.am-flag.am-flag-ai {
  background-position: -96px 0;
}

.am-flag.am-flag-ck {
  background-position: -216px -48px;
}

.am-flag.am-flag-cy {
  background-position: -48px -72px;
}

.am-flag.am-flag-vn {
  background-position: -120px -336px;
}

.am-flag.am-flag-gd {
  background-position: -240px -96px;
}

.am-flag.am-flag-am {
  background-position: -144px 0;
}

.am-flag.am-flag-mh {
  background-position: -192px -192px;
}

.am-flag.am-flag-ss {
  background-position: -144px -288px;
}

.am-flag.am-flag-ye {
  background-position: -216px -336px;
}

.am-flag.am-flag-fr {
  background-position: -168px -96px;
}

.am-flag.am-flag-re {
  background-position: -168px -96px;
}

.am-flag.am-flag-gf {
  background-position: -168px -96px;
}

.am-flag.am-flag-xk {
  background-position: -336px -336px;
}

.am-flag.am-flag-ht {
  background-position: -288px -120px;
}

.am-flag.am-flag-ws {
  background-position: -192px -336px;
}

.am-flag.am-flag-mu {
  background-position: -72px -216px;
}

.am-flag.am-flag-mm {
  background-position: -264px -192px;
}

.am-flag.am-flag-ie {
  background-position: 0 -144px;
}

.am-flag.am-flag-gr {
  background-position: -72px -120px;
}

.am-flag.am-flag-gt {
  background-position: -120px -120px;
}

.am-flag.am-flag-ba {
  background-position: 0 -24px;
}

.am-flag.am-flag-ua {
  background-position: -264px -312px;
}

.am-flag.am-flag-kg {
  background-position: -312px -144px;
}

.am-flag.am-flag-mc {
  background-position: -72px -192px;
}

.am-flag.am-flag-mw {
  background-position: -120px -216px;
}

.am-flag.am-flag-cf {
  background-position: -120px -48px;
}

.am-flag.am-flag-cn {
  background-position: -288px -48px;
}

.am-flag.am-flag-dk {
  background-position: -144px -72px;
}

.am-flag.am-flag-ve {
  background-position: -48px -336px;
}

.am-flag.am-flag-rs {
  background-position: -120px -264px;
}

.am-flag.am-flag-np {
  background-position: -24px -240px;
}

.am-flag.am-flag-bw {
  background-position: 0 -48px;
}

.am-flag.am-flag-cg {
  background-position: -144px -48px;
}

.am-flag.am-flag-kz {
  background-position: -144px -168px;
}

.am-flag.am-flag-do {
  background-position: -192px -72px;
}

.am-flag.am-flag-de {
  background-position: -96px -72px;
}

.am-flag.am-flag-ad {
  background-position: 0 0;
}

.am-flag.am-flag-rw {
  background-position: -168px -264px;
}

.am-flag.am-flag-tz {
  background-position: -240px -312px;
}

.am-flag.am-flag-be {
  background-position: -72px -24px;
}

.am-flag.am-flag-sl {
  background-position: -24px -288px;
}

.am-flag.am-flag-iq {
  background-position: -96px -144px;
}

.am-flag.am-flag-fm {
  background-position: -120px -96px;
}

.am-flag.am-flag-ge {
  background-position: -264px -96px;
}

.am-flag.am-flag-ne {
  background-position: -264px -216px;
}

.am-flag.am-flag-mq {
  background-position: -360px -192px;
}

.am-flag.am-flag-kw {
  background-position: -96px -168px;
}

.am-flag.am-flag-gl {
  background-position: -360px -96px;
}

.am-flag.am-flag-cz {
  background-position: -72px -72px;
}

.am-flag.am-flag-sh {
  background-position: -336px -264px;
}

.am-flag.am-flag-fi {
  background-position: -48px -96px;
}

.am-flag.am-flag-mn {
  background-position: -288px -192px;
}

.am-flag.am-flag-bd {
  background-position: -48px -24px;
}

.am-flag.am-flag-to {
  background-position: -120px -312px;
}

.am-flag.am-flag-mk {
  background-position: -216px -192px;
}

.am-flag.am-flag-ae {
  background-position: -24px 0;
}

.am-flag.am-flag-fo {
  background-position: -144px -96px;
}

.am-flag.am-flag-ls {
  background-position: -312px -168px;
}

.am-flag.am-flag-in {
  background-position: -72px -144px;
}

.am-flag.am-flag-ec {
  background-position: -240px -72px;
}

.am-flag.am-flag-pt {
  background-position: 0 -264px;
}

.am-flag.am-flag-pf {
  background-position: -192px -240px;
}

.am-flag.am-flag-bb, .am-flag.am-flag-bbd {
  background-position: -24px -24px;
}

.am-flag.am-flag-pl {
  background-position: -288px -240px;
}

.am-flag.am-flag-na {
  background-position: -216px -216px;
}

.am-flag.am-flag-jm {
  background-position: -216px -144px;
}

.am-flag.am-flag-me {
  background-position: -120px -192px;
}

.am-flag.am-flag-mwk {
  background-position: -120px -216px;
}

.am-flag.am-flag-dm {
  background-position: -168px -72px;
}

.am-flag.am-flag-bz {
  background-position: -48px -48px;
}

.am-flag.am-flag-si {
  background-position: -360px -264px;
}

.am-flag.am-flag-lc {
  background-position: -216px -168px;
}

.am-flag.am-flag-zw {
  background-position: -312px -336px;
}

.am-flag.am-flag-kn {
  background-position: -24px -168px;
}

.am-flag.am-flag-sy {
  background-position: -216px -288px;
}

.am-flag.am-flag-gw {
  background-position: -168px -120px;
}

.am-flag.am-flag-cw {
  background-position: -24px -72px;
}

.am-flag.am-flag-ky {
  background-position: -120px -168px;
}

.am-flag.am-flag-ag, .am-flag.am-flag-xcd {
  background-position: -72px 0;
}

.am-flag.am-flag-sz {
  background-position: -240px -288px;
}

.am-flag.am-flag-bg {
  background-position: -120px -24px;
}

.am-flag.am-flag-hk {
  background-position: -216px -120px;
}

.am-flag.am-flag-yt {
  background-position: -240px -336px;
}

.am-flag.am-flag-ni {
  background-position: -336px -216px;
}

.am-flag.am-flag-gm {
  background-position: 0 -120px;
}

.am-flag.am-flag-az {
  background-position: -360px 0;
}

.am-flag.am-flag-gs {
  background-position: -96px -120px;
}

.am-flag.am-flag-it {
  background-position: -168px -144px;
}

.am-flag.am-flag-es {
  background-position: -360px -72px;
}

.am-flag.am-flag-mf {
  background-position: -144px -192px;
}

.am-flag.am-flag-td {
  background-position: -288px -288px;
}

.am-flag.am-flag-tw {
  background-position: -216px -312px;
}

.am-flag.am-flag-mt {
  background-position: -48px -216px;
}

.am-flag.am-flag-pn {
  background-position: -312px -240px;
}

.am-flag.am-flag-wf {
  background-position: -168px -336px;
}

.am-flag.am-flag-us {
  background-position: -312px -312px;
}

.am-flag.am-flag-ml {
  background-position: -240px -192px;
}

.am-flag.am-flag-gi {
  background-position: -336px -96px;
}

.am-flag.am-flag-zm {
  background-position: -288px -336px;
}

.am-flag.am-flag-tl {
  background-position: -48px -312px;
}

.am-flag.am-flag-dj {
  background-position: -120px -72px;
}

.am-flag.am-flag-pw {
  background-position: -24px -264px;
}

.am-flag.am-flag-sg {
  background-position: -312px -264px;
}

.am-flag.am-flag-li {
  background-position: -240px -168px;
}

.am-flag.am-flag-vi {
  background-position: -96px -336px;
}

.am-flag.am-flag-hr {
  background-position: -264px -120px;
}

.am-flag.am-flag-ms {
  background-position: -24px -216px;
}

.am-flag.am-flag-kp {
  background-position: -48px -168px;
}

.am-flag.am-flag-sa {
  background-position: -192px -264px;
}

.am-flag.am-flag-ly {
  background-position: -24px -192px;
}

.am-flag.am-flag-cr {
  background-position: -336px -48px;
}

.am-flag.am-flag-nr {
  background-position: -48px -240px;
}

.am-flag.am-flag-sm {
  background-position: -48px -288px;
}

.am-flag.am-flag-za {
  background-position: -264px -336px;
}

.am-flag.am-flag-mx {
  background-position: -144px -216px;
}

.am-flag.am-flag-gy {
  background-position: -192px -120px;
}

.am-flag.am-flag-id {
  background-position: -360px -120px;
}

.am-flag.am-flag-py {
  background-position: -48px -264px;
}

.am-flag.am-flag-pk {
  background-position: -264px -240px;
}

.am-flag.am-flag-ax {
  background-position: -336px 0;
}

.am-flag.am-flag-bj {
  background-position: -192px -24px;
}

.am-flag.am-flag-dz {
  background-position: -216px -72px;
}

.am-flag.am-flag-ps {
  background-position: -360px -240px;
}

.am-flag.am-flag-gq {
  background-position: -48px -120px;
}

.am-flag.am-flag-tm {
  background-position: -72px -312px;
}

.am-flag.am-flag-bf {
  background-position: -96px -24px;
}

.am-flag.am-flag-my {
  background-position: -168px -216px;
}

.am-flag.am-flag-sd {
  background-position: -264px -264px;
}

.am-flag.am-flag-jo {
  background-position: -240px -144px;
}

.am-flag.am-flag-hu {
  background-position: -312px -120px;
}

.am-flag.am-flag-mp {
  background-position: -336px -192px;
}

.am-flag.am-flag-bo {
  background-position: -288px -24px;
}

.am-flag.am-flag-nl {
  background-position: -360px -216px;
}

.am-flag.am-flag-tf {
  background-position: -312px -288px;
}

.am-flag.am-flag-ma {
  background-position: -48px -192px;
}

.am-flag.am-flag-la {
  background-position: -168px -168px;
}

.am-flag.am-flag-jp {
  background-position: -264px -144px;
}

.am-flag.am-flag-fj {
  background-position: -72px -96px;
}

.am-flag.am-flag-sc {
  background-position: -240px -264px;
}

.am-flag.am-flag-er {
  background-position: -336px -72px;
}

.am-flag.am-flag-kh {
  background-position: -336px -144px;
}

.am-flag.am-flag-lv {
  background-position: 0 -192px;
}

.am-flag.am-flag-sb {
  background-position: -216px -264px;
}

.am-flag.am-flag-ao {
  background-position: -192px 0;
}

.am-flag.am-flag-eu {
  background-position: -24px -96px;
}

.am-flag.am-flag-lr {
  background-position: -288px -168px;
}

.am-flag.am-flag-tc {
  background-position: -264px -288px;
}

.am-flag.am-flag-bt {
  background-position: -360px -24px;
}

.am-flag.am-flag-bl {
  background-position: -216px -24px;
}

.am-flag.am-flag-tr {
  background-position: -144px -312px;
}

.am-flag.am-flag-km {
  background-position: 0 -168px;
}

.am-flag.am-flag-je {
  background-position: -192px -144px;
}

.am-flag.am-flag-is {
  background-position: -144px -144px;
}

.am-flag.am-flag-sr {
  background-position: -120px -288px;
}

.am-flag.am-flag-tv {
  background-position: -192px -312px;
}

.am-flag.am-flag-ph {
  background-position: -240px -240px;
}

.am-flag.am-flag-tj {
  background-position: 0 -312px;
}

.am-flag.am-flag-mv {
  background-position: -96px -216px;
}

.am-flag.am-flag-al {
  background-position: -120px 0;
}

.am-flag.am-flag-vu {
  background-position: -144px -336px;
}

.am-flag.am-flag-cd {
  background-position: -96px -48px;
}

.am-flag.am-flag-aw {
  background-position: -312px 0;
}

.am-flag.am-flag-nu {
  background-position: -72px -240px;
}

.am-flag.am-flag-bi {
  background-position: -168px -24px;
}

.am-flag.am-flag-uy {
  background-position: -336px -312px;
}

.am-flag.am-flag-ug {
  background-position: -288px -312px;
}

.am-flag.am-flag-ci {
  background-position: -192px -48px;
}

.am-flag.am-flag-tn {
  background-position: -96px -312px;
}

.am-flag.am-flag-nz {
  background-position: -96px -240px;
}

.am-flag.am-flag-no {
  background-position: 0 -240px;
}

.am-flag.am-flag-hn {
  background-position: -240px -120px;
}

.am-flag.am-flag-an {
  background-position: -168px 0;
}

.am-flag.am-flag-gh {
  background-position: -312px -96px;
}

.am-flag.am-flag-pr {
  background-position: -336px -240px;
}

.am-flag.am-flag-pa {
  background-position: -144px -240px;
}

.am-flag.am-flag-vc {
  background-position: -24px -336px;
}

.am-flag.am-flag-ng {
  background-position: -312px -216px;
}

.am-flag.am-flag-il {
  background-position: -24px -144px;
}

.am-flag.am-flag-au {
  background-position: -288px 0;
}

.am-flag.am-flag-qa {
  background-position: -72px -264px;
}

.am-flag.am-flag-mz {
  background-position: -192px -216px;
}

.am-flag.am-flag-tg {
  background-position: -336px -288px;
}

.am-flag.am-flag-st {
  background-position: -168px -288px;
}

.am-flag.am-flag-ki {
  background-position: -360px -144px;
}

.am-flag.am-flag-tk {
  background-position: -24px -312px;
}

.am-flag.am-flag-as {
  background-position: -240px 0;
}

.am-flag.am-flag-eg {
  background-position: -288px -72px;
}

.am-flag.am-flag-nc {
  background-position: -240px -216px;
}

.am-flag.am-flag-fk {
  background-position: -96px -96px;
}

.am-flag.am-flag-eh {
  background-position: -312px -72px;
}

.am-flag.am-flag-af {
  background-position: -48px 0;
}

.am-flag.am-flag-gb {
  background-position: -216px -96px;
}

.am-flag.am-flag-cu {
  background-position: -360px -48px;
}

.am-flag.am-flag-va {
  background-position: 0 -336px;
}

.am-flag.am-flag-sn {
  background-position: -72px -288px;
}

.am-flag.am-flag-sv {
  background-position: -192px -288px;
}

.am-flag.am-flag-th {
  background-position: -360px -288px;
}

.am-flag.am-flag-ru {
  background-position: -144px -264px;
}

.am-flag.am-flag-ic {
  background-position: -336px -120px;
}

.am-flag.am-flag-ch {
  background-position: -168px -48px;
}

.am-flag.am-flag-lu {
  background-position: -360px -168px;
}

.am-flag.am-flag-so {
  background-position: -96px -288px;
}

.am-flag.am-flag-bn {
  background-position: -264px -24px;
}

.am-flag.am-flag-at {
  background-position: -264px 0;
}

.am-flag.am-flag-md {
  background-position: -96px -192px;
}

.am-flag.am-flag-lb {
  background-position: -192px -168px;
}

.am-flag.am-flag-im {
  background-position: -48px -144px;
}

.am-flag.am-flag-by {
  background-position: -24px -48px;
}

.am-flag.am-flag-uz {
  background-position: -360px -312px;
}

.am-flag.am-flag-ke {
  background-position: -288px -144px;
}

.am-flag.am-flag-nf {
  background-position: -288px -216px;
}

.am-flag.am-flag-bm {
  background-position: -240px -24px;
}

.am-flag.am-flag-mr {
  background-position: 0 -216px;
}

.am-flag.am-flag-ro {
  background-position: -96px -264px;
}

.am-flag.am-flag-bs {
  background-position: -336px -24px;
}

.am-flag.am-flag-kr {
  background-position: -72px -168px;
}

.am-flag.am-flag-et {
  background-position: 0 -96px;
}

.am-flag.am-flag-om {
  background-position: -120px -240px;
}

.am-flag.am-flag-bh {
  background-position: -144px -24px;
}

.am-flag.am-flag-br {
  background-position: -312px -24px;
}

.am-flag.am-flag-gu {
  background-position: -144px -120px;
}

.am-flag.am-flag-gp {
  background-position: -168px -96px;
}

.am-flag.am-flag-pe {
  background-position: -168px -240px;
}

.am-flag.am-flag-se {
  background-position: -288px -264px;
}

.am-flag.am-flag-ca {
  background-position: -72px -48px;
}

.am-flag.am-flag-cl {
  background-position: -240px -48px;
}

.am-flag.am-flag-mo {
  background-position: -312px -192px;
}

.am-flag.am-flag-sk {
  background-position: 0 -288px;
}

.am-flag.am-flag-ir {
  background-position: -120px -144px;
}

.am-flag.am-flag-co {
  background-position: -312px -48px;
}

.am-flag.am-flag-mg {
  background-position: -168px -192px;
}

.am-flag.am-flag-lk {
  background-position: -264px -168px;
}

.am-flag.am-flag-lt {
  background-position: -336px -168px;
}

/* SELECTED FLAGS */
.am-selected-flag {
  background: no-repeat 14px 8px;
  min-width: 75px;
}

.am-selected-flag-auto {
  background-image: url('~@/assets/img/flags/auto.png');
}

.am-selected-flag-eu {
  background-image: url('~@/assets/img/flags/eu.png');
}

.am-selected-flag-tz {
  background-image: url('~@/assets/img/flags/tz.png');
}

.am-selected-flag-bd {
  background-image: url('~@/assets/img/flags/bd.png');
}

.am-selected-flag-zm {
  background-image: url('~@/assets/img/flags/zm.png');
}

.am-selected-flag-ss {
  background-image: url('~@/assets/img/flags/ss.png');
}

.am-selected-flag-np {
  background-image: url('~@/assets/img/flags/np.png');
}

.am-selected-flag-gl {
  background-image: url('~@/assets/img/flags/gl.png');
}

.am-selected-flag-bw {
  background-image: url('~@/assets/img/flags/bw.png');
}

.am-selected-flag-py {
  background-image: url('~@/assets/img/flags/py.png');
}

.am-selected-flag-cd {
  background-image: url('~@/assets/img/flags/cd.png');
}

.am-selected-flag-sn {
  background-image: url('~@/assets/img/flags/sn.png');
}

.am-selected-flag-ro {
  background-image: url('~@/assets/img/flags/ro.png');
}

.am-selected-flag-it {
  background-image: url('~@/assets/img/flags/it.png');
}

.am-selected-flag-mg {
  background-image: url('~@/assets/img/flags/mg.png');
}

.am-selected-flag-cf {
  background-image: url('~@/assets/img/flags/cf.png');
}

.am-selected-flag-va {
  background-image: url('~@/assets/img/flags/va.png');
}

.am-selected-flag-ly {
  background-image: url('~@/assets/img/flags/ly.png');
}

.am-selected-flag-uz {
  background-image: url('~@/assets/img/flags/uz.png');
}

.am-selected-flag-sc {
  background-image: url('~@/assets/img/flags/sc.png');
}

.am-selected-flag-bf {
  background-image: url('~@/assets/img/flags/bf.png');
}

.am-selected-flag-kr {
  background-image: url('~@/assets/img/flags/kr.png');
}

.am-selected-flag-pk {
  background-image: url('~@/assets/img/flags/pk.png');
}

.am-selected-flag-td {
  background-image: url('~@/assets/img/flags/td.png');
}

.am-selected-flag-pl {
  background-image: url('~@/assets/img/flags/pl.png');
}

.am-selected-flag-be {
  background-image: url('~@/assets/img/flags/be.png');
}

.am-selected-flag-so {
  background-image: url('~@/assets/img/flags/so.png');
}

.am-selected-flag-ls {
  background-image: url('~@/assets/img/flags/ls.png');
}

.am-selected-flag-kp {
  background-image: url('~@/assets/img/flags/kp.png');
}

.am-selected-flag-jm {
  background-image: url('~@/assets/img/flags/jm.png');
}

.am-selected-flag-fm {
  background-image: url('~@/assets/img/flags/fm.png');
}

.am-selected-flag-as {
  background-image: url('~@/assets/img/flags/as.png');
}

.am-selected-flag-cy {
  background-image: url('~@/assets/img/flags/cy.png');
}

.am-selected-flag-aw {
  background-image: url('~@/assets/img/flags/aw.png');
}

.am-selected-flag-sg {
  background-image: url('~@/assets/img/flags/sg.png');
}

.am-selected-flag-eg {
  background-image: url('~@/assets/img/flags/eg.png');
}

.am-selected-flag-sd {
  background-image: url('~@/assets/img/flags/sd.png');
}

.am-selected-flag-vg {
  background-image: url('~@/assets/img/flags/vg.png');
}

.am-selected-flag-az {
  background-image: url('~@/assets/img/flags/az.png');
}

.am-selected-flag-gr {
  background-image: url('~@/assets/img/flags/gr.png');
}

.am-selected-flag-si {
  background-image: url('~@/assets/img/flags/si.png');
}

.am-selected-flag-cg {
  background-image: url('~@/assets/img/flags/cg.png');
}

.am-selected-flag-sa {
  background-image: url('~@/assets/img/flags/sa.png');
}

.am-selected-flag-do {
  background-image: url('~@/assets/img/flags/do.png');
}

.am-selected-flag-us {
  background-image: url('~@/assets/img/flags/us.png');
}

.am-selected-flag-gu {
  background-image: url('~@/assets/img/flags/gu.png');
}

.am-selected-flag-gp {
  background-image: url('~@/assets/img/flags/fr.png');
}

.am-selected-flag-jo {
  background-image: url('~@/assets/img/flags/jo.png');
}

.am-selected-flag-mt {
  background-image: url('~@/assets/img/flags/mt.png');
}

.am-selected-flag-ru {
  background-image: url('~@/assets/img/flags/ru.png');
}

.am-selected-flag-nc {
  background-image: url('~@/assets/img/flags/nc.png');
}

.am-selected-flag-sk {
  background-image: url('~@/assets/img/flags/sk.png');
}

.am-selected-flag-sh {
  background-image: url('~@/assets/img/flags/sh.png');
}

.am-selected-flag-pe {
  background-image: url('~@/assets/img/flags/pe.png');
}

.am-selected-flag-in {
  background-image: url('~@/assets/img/flags/in.png');
}

.am-selected-flag-fj {
  background-image: url('~@/assets/img/flags/fj.png');
}

.am-selected-flag-sz {
  background-image: url('~@/assets/img/flags/sz.png');
}

.am-selected-flag-pg {
  background-image: url('~@/assets/img/flags/pg.png');
}

.am-selected-flag-dj {
  background-image: url('~@/assets/img/flags/dj.png');
}

.am-selected-flag-gy {
  background-image: url('~@/assets/img/flags/gy.png');
}

.am-selected-flag-bg {
  background-image: url('~@/assets/img/flags/bg.png');
}

.am-selected-flag-hk {
  background-image: url('~@/assets/img/flags/hk.png');
}

.am-selected-flag-fi {
  background-image: url('~@/assets/img/flags/fi.png');
}

.am-selected-flag-cw {
  background-image: url('~@/assets/img/flags/cw.png');
}

.am-selected-flag-fk {
  background-image: url('~@/assets/img/flags/fk.png');
}

.am-selected-flag-gh {
  background-image: url('~@/assets/img/flags/gh.png');
}

.am-selected-flag-dm {
  background-image: url('~@/assets/img/flags/dm.png');
}

.am-selected-flag-ao {
  background-image: url('~@/assets/img/flags/ao.png');
}

.am-selected-flag-pa {
  background-image: url('~@/assets/img/flags/pa.png');
}

.am-selected-flag-bb {
  background-image: url('~@/assets/img/flags/bb.png');
}

.am-selected-flag-mo {
  background-image: url('~@/assets/img/flags/mo.png');
}

.am-selected-flag-by {
  background-image: url('~@/assets/img/flags/by.png');
}

.am-selected-flag-lb {
  background-image: url('~@/assets/img/flags/lb.png');
}

.am-selected-flag-sy {
  background-image: url('~@/assets/img/flags/sy.png');
}

.am-selected-flag-vn {
  background-image: url('~@/assets/img/flags/vn.png');
}

.am-selected-flag-mm {
  background-image: url('~@/assets/img/flags/mm.png');
}

.am-selected-flag-cz {
  background-image: url('~@/assets/img/flags/cz.png');
}

.am-selected-flag-br {
  background-image: url('~@/assets/img/flags/br.png');
}

.am-selected-flag-ne {
  background-image: url('~@/assets/img/flags/ne.png');
}

.am-selected-flag-bj {
  background-image: url('~@/assets/img/flags/bj.png');
}

.am-selected-flag-at {
  background-image: url('~@/assets/img/flags/at.png');
}

.am-selected-flag-pt {
  background-image: url('~@/assets/img/flags/pt.png');
}

.am-selected-flag-cr {
  background-image: url('~@/assets/img/flags/cr.png');
}

.am-selected-flag-cv {
  background-image: url('~@/assets/img/flags/cv.png');
}

.am-selected-flag-ba {
  background-image: url('~@/assets/img/flags/ba.png');
}

.am-selected-flag-tv {
  background-image: url('~@/assets/img/flags/tv.png');
}

.am-selected-flag-ch {
  background-image: url('~@/assets/img/flags/ch.png');
}

.am-selected-flag-ki {
  background-image: url('~@/assets/img/flags/ki.png');
}

.am-selected-flag-mf {
  background-image: url('~@/assets/img/flags/mf.png');
}

.am-selected-flag-jp {
  background-image: url('~@/assets/img/flags/jp.png');
}

.am-selected-flag-bl {
  background-image: url('~@/assets/img/flags/bl.png');
}

.am-selected-flag-tg {
  background-image: url('~@/assets/img/flags/tg.png');
}

.am-selected-flag-bh {
  background-image: url('~@/assets/img/flags/bh.png');
}

.am-selected-flag-mc {
  background-image: url('~@/assets/img/flags/mc.png');
}

.am-selected-flag-am {
  background-image: url('~@/assets/img/flags/am.png');
}

.am-selected-flag-pf {
  background-image: url('~@/assets/img/flags/pf.png');
}

.am-selected-flag-la {
  background-image: url('~@/assets/img/flags/la.png');
}

.am-selected-flag-no {
  background-image: url('~@/assets/img/flags/no.png');
}

.am-selected-flag-kg {
  background-image: url('~@/assets/img/flags/kg.png');
}

.am-selected-flag-ad {
  background-image: url('~@/assets/img/flags/ad.png');
}

.am-selected-flag-ht {
  background-image: url('~@/assets/img/flags/ht.png');
}

.am-selected-flag-is {
  background-image: url('~@/assets/img/flags/is.png');
}

.am-selected-flag-mh {
  background-image: url('~@/assets/img/flags/mh.png');
}

.am-selected-flag-ir {
  background-image: url('~@/assets/img/flags/ir.png');
}

.am-selected-flag-bo {
  background-image: url('~@/assets/img/flags/bo.png');
}

.am-selected-flag-pw {
  background-image: url('~@/assets/img/flags/pw.png');
}

.am-selected-flag-il {
  background-image: url('~@/assets/img/flags/il.png');
}

.am-selected-flag-ky {
  background-image: url('~@/assets/img/flags/ky.png');
}

.am-selected-flag-lt {
  background-image: url('~@/assets/img/flags/lt.png');
}

.am-selected-flag-ph {
  background-image: url('~@/assets/img/flags/ph.png');
}

.am-selected-flag-gq {
  background-image: url('~@/assets/img/flags/gq.png');
}

.am-selected-flag-tw {
  background-image: url('~@/assets/img/flags/tw.png');
}

.am-selected-flag-sl {
  background-image: url('~@/assets/img/flags/sl.png');
}

.am-selected-flag-ga {
  background-image: url('~@/assets/img/flags/ga.png');
}

.am-selected-flag-tj {
  background-image: url('~@/assets/img/flags/tj.png');
}

.am-selected-flag-ae {
  background-image: url('~@/assets/img/flags/ae.png');
}

.am-selected-flag-md {
  background-image: url('~@/assets/img/flags/md.png');
}

.am-selected-flag-lk {
  background-image: url('~@/assets/img/flags/lk.png');
}

.am-selected-flag-co {
  background-image: url('~@/assets/img/flags/co.png');
}

.am-selected-flag-tl {
  background-image: url('~@/assets/img/flags/tl.png');
}

.am-selected-flag-kn {
  background-image: url('~@/assets/img/flags/kn.png');
}

.am-selected-flag-me {
  background-image: url('~@/assets/img/flags/me.png');
}

.am-selected-flag-af {
  background-image: url('~@/assets/img/flags/af.png');
}

.am-selected-flag-cl {
  background-image: url('~@/assets/img/flags/cl.png');
}

.am-selected-flag-om {
  background-image: url('~@/assets/img/flags/om.png');
}

.am-selected-flag-ax {
  background-image: url('~@/assets/img/flags/ax.png');
}

.am-selected-flag-hn {
  background-image: url('~@/assets/img/flags/hn.png');
}

.am-selected-flag-mw {
  background-image: url('~@/assets/img/flags/mw.png');
}

.am-selected-flag-hu {
  background-image: url('~@/assets/img/flags/hu.png');
}

.am-selected-flag-er {
  background-image: url('~@/assets/img/flags/er.png');
}

.am-selected-flag-bt {
  background-image: url('~@/assets/img/flags/bt.png');
}

.am-selected-flag-gg {
  background-image: url('~@/assets/img/flags/gg.png');
}

.am-selected-flag-gm {
  background-image: url('~@/assets/img/flags/gm.png');
}

.am-selected-flag-ai {
  background-image: url('~@/assets/img/flags/ai.png');
}

.am-selected-flag-mp {
  background-image: url('~@/assets/img/flags/mp.png');
}

.am-selected-flag-mn {
  background-image: url('~@/assets/img/flags/mn.png');
}

.am-selected-flag-lu {
  background-image: url('~@/assets/img/flags/lu.png');
}

.am-selected-flag-ar {
  background-image: url('~@/assets/img/flags/ar.png');
}

.am-selected-flag-vu {
  background-image: url('~@/assets/img/flags/vu.png');
}

.am-selected-flag-iq {
  background-image: url('~@/assets/img/flags/iq.png');
}

.am-selected-flag-zw {
  background-image: url('~@/assets/img/flags/zw.png');
}

.am-selected-flag-de {
  background-image: url('~@/assets/img/flags/de.png');
}

.am-selected-flag-ws {
  background-image: url('~@/assets/img/flags/ws.png');
}

.am-selected-flag-nl {
  background-image: url('~@/assets/img/flags/nl.png');
}

.am-selected-flag-bz {
  background-image: url('~@/assets/img/flags/bz.png');
}

.am-selected-flag-za {
  background-image: url('~@/assets/img/flags/za.png');
}

.am-selected-flag-na {
  background-image: url('~@/assets/img/flags/na.png');
}

.am-selected-flag-ec {
  background-image: url('~@/assets/img/flags/ec.png');
}

.am-selected-flag-vc {
  background-image: url('~@/assets/img/flags/vc.png');
}

.am-selected-flag-nr {
  background-image: url('~@/assets/img/flags/nr.png');
}

.am-selected-flag-ci {
  background-image: url('~@/assets/img/flags/ci.png');
}

.am-selected-flag-ge {
  background-image: url('~@/assets/img/flags/ge.png');
}

.am-selected-flag-mx {
  background-image: url('~@/assets/img/flags/mx.png');
}

.am-selected-flag-nz {
  background-image: url('~@/assets/img/flags/nz.png');
}

.am-selected-flag-rw {
  background-image: url('~@/assets/img/flags/rw.png');
}

.am-selected-flag-gb {
  background-image: url('~@/assets/img/flags/gb.png');
}

.am-selected-flag-bm {
  background-image: url('~@/assets/img/flags/bm.png');
}

.am-selected-flag-lc {
  background-image: url('~@/assets/img/flags/lc.png');
}

.am-selected-flag-uy {
  background-image: url('~@/assets/img/flags/uy.png');
}

.am-selected-flag-my {
  background-image: url('~@/assets/img/flags/my.png');
}

.am-selected-flag-th {
  background-image: url('~@/assets/img/flags/th.png');
}

.am-selected-flag-lr {
  background-image: url('~@/assets/img/flags/lr.png');
}

.am-selected-flag-fr {
  background-image: url('~@/assets/img/flags/fr.png');
}

.am-selected-flag-re {
  background-image: url('~@/assets/img/flags/fr.png');
}

.am-selected-flag-gf {
  background-image: url('~@/assets/img/flags/fr.png');
}

.am-selected-flag-ng {
  background-image: url('~@/assets/img/flags/ng.png');
}

.am-selected-flag-nf {
  background-image: url('~@/assets/img/flags/nf.png');
}

.am-selected-flag-au {
  background-image: url('~@/assets/img/flags/au.png');
}

.am-selected-flag-ma {
  background-image: url('~@/assets/img/flags/ma.png');
}

.am-selected-flag-pr {
  background-image: url('~@/assets/img/flags/pr.png');
}

.am-selected-flag-ug {
  background-image: url('~@/assets/img/flags/ug.png');
}

.am-selected-flag-ni {
  background-image: url('~@/assets/img/flags/ni.png');
}

.am-selected-flag-dk {
  background-image: url('~@/assets/img/flags/dk.png');
}

.am-selected-flag-qa {
  background-image: url('~@/assets/img/flags/qa.png');
}

.am-selected-flag-ua {
  background-image: url('~@/assets/img/flags/ua.png');
}

.am-selected-flag-ps {
  background-image: url('~@/assets/img/flags/ps.png');
}

.am-selected-flag-sr {
  background-image: url('~@/assets/img/flags/sr.png');
}

.am-selected-flag-bn {
  background-image: url('~@/assets/img/flags/bn.png');
}

.am-selected-flag-lv {
  background-image: url('~@/assets/img/flags/lv.png');
}

.am-selected-flag-kh {
  background-image: url('~@/assets/img/flags/kh.png');
}

.am-selected-flag-mk {
  background-image: url('~@/assets/img/flags/mk.png');
}

.am-selected-flag-bs {
  background-image: url('~@/assets/img/flags/bs.png');
}

.am-selected-flag-ms {
  background-image: url('~@/assets/img/flags/ms.png');
}

.am-selected-flag-dz {
  background-image: url('~@/assets/img/flags/dz.png');
}

.am-selected-flag-hr {
  background-image: url('~@/assets/img/flags/hr.png');
}

.am-selected-flag-fo {
  background-image: url('~@/assets/img/flags/fo.png');
}

.am-selected-flag-ve {
  background-image: url('~@/assets/img/flags/ve.png');
}

.am-selected-flag-cn {
  background-image: url('~@/assets/img/flags/cn.png');
}

.am-selected-flag-ie {
  background-image: url('~@/assets/img/flags/ie.png');
}

.am-selected-flag-al {
  background-image: url('~@/assets/img/flags/al.png');
}

.am-selected-flag-im {
  background-image: url('~@/assets/img/flags/im.png');
}

.am-selected-flag-to {
  background-image: url('~@/assets/img/flags/to.png');
}

.am-selected-flag-vi {
  background-image: url('~@/assets/img/flags/vi.png');
}

.am-selected-flag-mr {
  background-image: url('~@/assets/img/flags/mr.png');
}

.am-selected-flag-gw {
  background-image: url('~@/assets/img/flags/gw.png');
}

.am-selected-flag-st {
  background-image: url('~@/assets/img/flags/st.png');
}

.am-selected-flag-cu {
  background-image: url('~@/assets/img/flags/cu.png');
}

.am-selected-flag-tc {
  background-image: url('~@/assets/img/flags/tc.png');
}

.am-selected-flag-ag {
  background-image: url('~@/assets/img/flags/ag.png');
}

.am-selected-flag-ee {
  background-image: url('~@/assets/img/flags/ee.png');
}

.am-selected-flag-ca {
  background-image: url('~@/assets/img/flags/ca.png');
}

.am-selected-flag-gt {
  background-image: url('~@/assets/img/flags/gt.png');
}

.am-selected-flag-ye {
  background-image: url('~@/assets/img/flags/ye.png');
}

.am-selected-flag-es {
  background-image: url('~@/assets/img/flags/es.png');
}

.am-selected-flag-tk {
  background-image: url('~@/assets/img/flags/tk.png');
}

.am-selected-flag-sm {
  background-image: url('~@/assets/img/flags/sm.png');
}

.am-selected-flag-et {
  background-image: url('~@/assets/img/flags/et.png');
}

.am-selected-flag-tn {
  background-image: url('~@/assets/img/flags/tn.png');
}

.am-selected-flag-tm {
  background-image: url('~@/assets/img/flags/tm.png');
}

.am-selected-flag-li {
  background-image: url('~@/assets/img/flags/li.png');
}

.am-selected-flag-mv {
  background-image: url('~@/assets/img/flags/mv.png');
}

.am-selected-flag-cm {
  background-image: url('~@/assets/img/flags/cm.png');
}

.am-selected-flag-ke {
  background-image: url('~@/assets/img/flags/ke.png');
}

.am-selected-flag-ml {
  background-image: url('~@/assets/img/flags/ml.png');
}

.am-selected-flag-sb {
  background-image: url('~@/assets/img/flags/sb.png');
}

.am-selected-flag-rs {
  background-image: url('~@/assets/img/flags/rs.png');
}

.am-selected-flag-sv {
  background-image: url('~@/assets/img/flags/sv.png');
}

.am-selected-flag-id {
  background-image: url('~@/assets/img/flags/id.png');
}

.am-selected-flag-tr {
  background-image: url('~@/assets/img/flags/tr.png');
}

.am-selected-flag-se {
  background-image: url('~@/assets/img/flags/se.png');
}

.am-selected-flag-mz {
  background-image: url('~@/assets/img/flags/mz.png');
}

.am-selected-flag-mq {
  background-image: url('~@/assets/img/flags/mq.png');
}

.am-selected-flag-bi {
  background-image: url('~@/assets/img/flags/bi.png');
}

.am-selected-flag-je {
  background-image: url('~@/assets/img/flags/je.png');
}

.am-selected-flag-nu {
  background-image: url('~@/assets/img/flags/nu.png');
}

.am-selected-flag-ck {
  background-image: url('~@/assets/img/flags/ck.png');
}

.am-selected-flag-kz {
  background-image: url('~@/assets/img/flags/kz.png');
}

.am-selected-flag-mu {
  background-image: url('~@/assets/img/flags/mu.png');
}

.am-selected-flag-kw {
  background-image: url('~@/assets/img/flags/kw.png');
}

.am-selected-flag-gi {
  background-image: url('~@/assets/img/flags/gi.png');
}

.am-selected-flag-tt {
  background-image: url('~@/assets/img/flags/tt.png');
}

.am-selected-flag-km {
  background-image: url('~@/assets/img/flags/km.png');
}

.am-selected-flag-gn {
  background-image: url('~@/assets/img/flags/gn.png');
}

.am-selected-flag-yt {
  background-image: url('~@/assets/img/flags/yt.png');
}

.am-selected-flag-xk {
  background-image: url('~@/assets/img/flags/xk.png');
}

.am-selected-flag input {
  padding-left: 46px;
  background: transparent;
}
