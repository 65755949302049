
          @import "@/assets/css/_variables.scss";
        

// Params in px
$sizes: 24, 28, 32, 34, 36, 40, 48, 54, 56, 60, 64, 80;
$margins: -10, -8, -5, 4, 5, 8, 10, 12, 15, 16, 28;

.adm-avatar {
  box-sizing: content-box;
  display: inline-flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: $adm-white;
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  background-position: 50% 50%;
  background-size: cover;

  @each $size in $sizes {
    &.size-#{$size} {
      height: #{$size}px;
      width: #{$size}px;
      font-size: #{ calc($size / 2.6) }px;
    }
  }

  @each $margin in $margins {
    &.margin-right-#{$margin} {
      margin-right: #{$margin}px;
    }

    &.margin-left-#{$margin} {
      margin-left: #{$margin}px;
    }
  }
}
