
          @import "@/assets/css/_variables.scss";
        

.adm--dialog {
  .change-plan-cards {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    @include phone-down {
      flex-direction: column;
    }

    &__change-plan-card {
      padding: 16px;
      border: 1px solid var(--primary-600);
      border-radius: 8px;
      flex: 1;

      &.new {
        border-color:  var(--primary-900);
        background-color: var(--primary-200);

        .adm-icon {
          color: $yellow-900;
        }
      }

      &__title {
        font-size: 13px;
        line-height: 16px;
      }

      &__features-list {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &__feature {
          display: flex;
          align-items: flex-start;
          gap: 4px;
          font-size: 13px;
        }
      }
    }

    &__arrow {
      align-self: center;
      justify-self: center;

      @include phone-down {
        transform: rotate(90deg);
      }
    }
  }

  .new-plan-block {
    box-shadow: 0 2px 7px -1px rgba(0, 0, 0, 0.12);
    border-radius: 5px;

    &__header {
      padding: 12px 16px;
      background-color: var(--primary-200);

      &__title {
        font-size: 16px;

        &__icon {
          height: 32px;
          width: 32px;
        }
      }

      &__price-interval {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &__price {
          font-size: 16px;
        }
      }
    }

    &__body {
      padding: 0 24px;
      overflow: hidden;

      &__row {
        padding: 16px 0;
        min-height: 76px;

        &.with-charge {
          border-bottom: 1px dashed $shade-300;
        }

        &.with-credit {
          min-height: auto;
          padding-bottom: 0;
        }

        &__title, &__title__text {
          font-size: 15px;
        }

        &__amount {
          font-size: 15px;
          color: var(--primary-900);
        }
      }
    }

    &__grey-text {
      font-size: 13px;
      line-height: 16px;
      color: $shade-500;
    }
  }
}
