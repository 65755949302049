
          @import "@/assets/css/_variables.scss";
        

.adm-checkbox-buttons-group {
  &--next-is-selected.adm-button {
    border-right: 1px solid transparent;
  }
  .adm-button.is-hover-disabled-for-active-button {
    pointer-events: none;
  }

  .adm-button__size__medium {
    flex-grow: 1;
  }
}
