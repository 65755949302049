
          @import "@/assets/css/_variables.scss";
        

.manage-appointment-form {
  .section:not(:last-child) {
    margin-bottom: 1.5rem;

    .search {
      .el-input__inner {
        background: $shade-200;
      }
    }

    .services {
      margin-top: 0.75rem;

      .popular-services-title {
        padding-bottom: 0.25rem;
        font-size: 12px;
      }
    }
  }

  .select-datetime {
    .el-icon-loading {
      display: none;
    }

    .is-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .el-icon-loading {
        display: block;
        position: absolute;
        right: 8px;
      }

      .el-input__suffix {
        display: none;
      }
    }
  }
}
