
          @import "@/assets/css/_variables.scss";
        

.share-options {

  &__desc {
    color: $shade-900;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;

    @include phone-up {
      grid-template-columns: repeat(3, 1fr);
    }

    &--two-columns {
      grid-template-columns: repeat(2, 1fr);
    }

    &__card {
      margin: 0 auto;
      align-items: center;
      padding: 24px 12px;
      background: $shade-150;
      border-radius: 4px;
      cursor: pointer;
      width: 100%;
      position: relative;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        background: linear-gradient(180deg, var(--primary-400) 0%, var(--primary-200) 100%);
        box-shadow: 0 0 0 2px var(--primary-600);
        transition: .2s linear 0s;

        .share-options__items__card__title {
          color: var(--primary-900);
        }
      }

      &__img {
        height: 64px;
        display: flex;
        margin: 0 auto 18px;
      }

      &__title {
        text-align: center;
        color: $shade-900;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 4px;
      }

      &__desc {
        text-align: center;
        color: $shade-800;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
