
          @import "@/assets/css/_variables.scss";
        

.adm-limit-reached {
  .adm-modal__title {
    display: flex;
    align-items: center;
  }
}
